import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, template } from 'lodash';
import { compose, defaultProps } from 'recompose';
import moment from 'moment';

import { parseTemplateString } from 'Common/utils';
import { SimpleButton, Button, PropTypeSafety } from 'Common/components/Form';
import arrow from 'public/images/accordian-open.svg';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import { csePropTypes } from '../propTypes';

class InputGroup extends Component {
  static contextTypes = {
    theme: PropTypes.object
  };

  static propTypes = {
    ...csePropTypes,
    computedStyles: PropTypes.shape({
      modalBase: PropTypes.object,
      modalTitle: PropTypes.object,
      modalButtonsContainer: PropTypes.object,
      nowrap: PropTypes.object,
      group: PropTypes.object,
      label: PropTypes.func,
      defaultLabel: PropTypes.object,
      inlineLabelButton: PropTypes.object,
      inlineButton: PropTypes.object,
      base: PropTypes.object,
      title: PropTypes.object
    }),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    statePath: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    collapsible: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
    actions: PropTypes.shape({
      onAdd: PropTypes.func.isRequired,
      onRemove: PropTypes.func.isRequired,
      setState: PropTypes.func.isRequired
    }).isRequired,
    triggers: PropTypes.shape({
      onClick: PropTypes.func,
      onModalContentClear: PropTypes.func.isRequired,
      onModalContentUpdate: PropTypes.func.isRequired
    }).isRequired,
    disabled: PropTypes.bool,
    config: PropTypes.objectOf(PropTypes.any),
    addButtonLabel: PropTypes.string
  };

  static defaultProps = {
    id: '',
    label: '',
    title: '',
    computedStyles: {},
    multiple: true,
    collapsible: true,
    triggers: {},
    disabled: false,
    config: {},
    addButtonLabel: null,
    children: null
  };

  getMaxAttemptNumber = value =>
    Math.max(
      ...value
        .filter(attempt => attempt.attemptNumber)
        .map(attempt => attempt.attemptNumber)
    ) + 1;

  shouldComponentUpdate(nextProps) {
    return !!nextProps.data;
  }

  componentDidUpdate(prevProps) {
    this.focusNewInstance(prevProps);
  }

  handleLabelClick = e => {
    const { triggers } = this.props;
    if (triggers && triggers.onClick) {
      e.stopPropagation();
      const [methodName, ...args] = [].concat(triggers.onClick);
      this.props[methodName](this.props.statePath, ...args);
    }
  };

  focusNewInstance = prevProps => {
    const didLengthChange =
      get(prevProps, 'length', 0) < get(this.props, 'length', 0);
    if (didLengthChange) {
      const section = document.querySelector(
        `section[data-schema-path='${this.props.schemaPath}.instances[${
          prevProps.length
        }]']`
      );
      const element = section ? section.querySelectorAll('input')[0] : null;
      if (element) {
        element.focus();
      }
    }
  };

  updateWithInput = children => {
    if (children) {
      const lastChild = children[children.length - 1];
      lastChild.props.actions.emitInputUpdate(
        lastChild.props,
        lastChild.props.model
      );
    }
  };

  updateTaskAttemptInformation = attempts => ({
    ...this.props,
    attemptNumberOverride: Math.min(5, this.getMaxAttemptNumber(attempts))
  });

  addGroup = () => {
    const { instances, actions, children, value } = this.props;
    let props = this.props;
    if (
      this.props.$id &&
      this.props.$id.match('tasks\\[\\d\\].form.additional.attempts.attempt')
    ) {
      props = this.updateTaskAttemptInformation(value);
    }
    actions.onAdd({
      ...props,
      instances: instances.map(instance => ({
        ...instance,
        collapsed: true
      }))
    });
    // This is works similar as a hook
    this.updateWithInput(children);
  };

  removeGroup = instance => {
    instance.actions.onRemove(instance);
  };

  collapseGroup = instance => {
    const { actions: { setState } } = instance;
    setState({
      ...instance,
      collapsed: !instance.collapsed
    });
  };

  // TODO Make a trigger
  promptConfirm = instance => {
    const { onModalContentUpdate, onModalContentClear,onUpdateSeriousness } = this.props.triggers;
    const {id}= this.props
   
    const handleRemoveGroup = e => {     
      e.stopPropagation();
      onModalContentClear();
      setTimeout(() => {
        instance.actions.onRemove(instance)
        // Check the ID and call onUpdateSeriousness afterward
        if (id === "reactionItem") {
          onUpdateSeriousness();
        }
      }, 10)
      
    };
    const handleDismiss = () => onModalContentClear();
    const content = this.renderConfirmation(handleRemoveGroup, handleDismiss);
    onModalContentUpdate(content, false, true, 'REMOVE_GROUP');
  };

  renderConfirmation = (handleConfirm, handleDismiss) => {
    const { computedStyles } = this.props;
    return (
      <PropTypeSafety>
        <div className={computedStyles.modalBase}>
          <span className={computedStyles.modalTitle}>Remove Information</span>
          Are you sure you want to remove this data? This action cannot be
          undone.
          <div className={computedStyles.modalButtonsContainer}>
            <SimpleButton onClick={handleDismiss}>Cancel</SimpleButton>
            <SimpleButton onClick={handleConfirm} primary>
              Confirm
            </SimpleButton>
          </div>
        </div>
      </PropTypeSafety>
    );
  };

  renderGroupHeader = (instance, label, className) => {
    const group = instance.props;

    if (!label || (!this.props.collapsible && this.props.multiple === true)) {
      return null;
    }

    const collapse = e => {
      if (this.props.collapsible) {
        e.preventDefault();
        e.stopPropagation();
        this.collapseGroup(group);
      }
    };

    const onKeyPress = e => {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        collapse();
      }
    };

    const formatMomentChangeToNotSpecifiedOnInvalidDate = l => {
      const output = template(l)({ moment });
      // dont show attempt 2, Invalid date ..remove attempt for invalid date and show new attempt text
      return output.includes('Invalid date') ? 'New Attempt' : output;
    };

    const formattedLabel =
      label.indexOf('<%=') >= 0
        ? formatMomentChangeToNotSpecifiedOnInvalidDate(label)
        : parseTemplateString(label, group);

    return (
      <h3 className={className} onClick={collapse}>
        <span role="link" onClick={this.handleLabelClick}>
          {formattedLabel}
        </span>
        {this.props.collapsible ? (
          <img onKeyPress={onKeyPress} role="button" tabIndex="0" src={arrow} />
        ) : null}
      </h3>
    );
  };

  renderInstance = (instance, i) => {
    const {
      collapsible,
      computedStyles,
      data: { location },
      model,
      statePath
    } = this.props;

    const { label, collapsed } = instance.props;
    const inheritedProps = instance.props;
    const nowrapClass = !label && i > 0 ? computedStyles.nowrap : null;
    const groupClassName = `${computedStyles.group} ${nowrapClass}`;
    const labelStyles = `${computedStyles.label(collapsed)} ${
      computedStyles.defaultLabel
    }`;
    // The location value is different for tasks
    const isTasks = (location.path || '').indexOf('/tasks') === 0;
    // using `window.location` because react router doesn't consistently populate `location`
    const isMasterCase = window.location.pathname.indexOf('/review') > 0;

    const isAE = location.page === 'ae';
    const isAEVersionZero =
      get(model, 'subcases.adverseEvent.version', null) === '00';

    const isPQ = location.page === 'pq';
    const isPQVersionZero =
      get(model, 'subcases.productQuality.version', null) === '00';
    // for the pq products input group, the user can now add products after submitting and creating a new version
    // defaults to true so that || !isSubmittedProduct defaults to false and is a nonfactor in most cases
    let isSubmittedProduct = true;
    // check to see if the product is in a submitted version
    if (isPQ && statePath === 'products') {
      const trilogyProductId = get(
        model,
        `${inheritedProps.$id}.trilogyProductId`,
        null
      );
      isSubmittedProduct = (
        get(model, 'subcases.productQuality.subcaseVersions') || []
      )
        .flatMap(version =>
          (get(version, 'products') || []).map(
            product => product.trilogyProductId
          )
        )
        .includes(trilogyProductId);
    }
    const isSubmittedReaction =
      isAE && statePath === 'reaction'
        ? get(model, `subcases.adverseEvent.reactions.reaction[${i}].submitted`)
        : true;

    const isNewCase = get(model, 'created') === null;

    const showRemoveButton =
      i > 0 && // only show remove button for ADDED instances AND
      (isNewCase || // if we're on a new case
      isTasks || // or we're viewing tasks
      (isMasterCase && (isAEVersionZero || isPQVersionZero)) || // or we're on mastercase with at least one subcase at version "00"
      (isAE && (isAEVersionZero || !isSubmittedReaction)) || // or we're on AE with version "00" or the reaction has NOT been submitted
        (isPQ && (isPQVersionZero || !isSubmittedProduct))); // or we're on PQ with version "00" or the product has NOT been submitted

    return (
      <section
        key={inheritedProps.schemaPath}
        className={groupClassName}
        data-group={inheritedProps.statePath}
        data-id={instance.props.$id}
        data-schema-path={instance.props.schemaPath}
      >
        {this.renderGroupHeader(instance, label, labelStyles)}
        {collapsible && collapsed ? null : instance}

        {showRemoveButton ? this.renderRemoveButton(instance.props) : null}
      </section>
    );
  };

  renderAddButton = () => {
    const { $id, title, disabled, addButtonLabel, label } = this.props;
    const buttonLabel = addButtonLabel || `Add ${label || title}`;
    return (
      <Button
        $id={`${$id}-add-button`}
        type="add"
        onClick={this.addGroup}
        disabled={disabled}
        label={buttonLabel}
      />
    );
  };

  renderRemoveButton = instance => {
    const { collapsible, multiple, disabled, computedStyles } = this.props;

    const className = collapsible
      ? computedStyles.inlineLabelButton
      : computedStyles.inlineButton;

    const handleClick = () => this.promptConfirm(instance);
    if (multiple === false) return null;
    return (
      <Button
        label=" "
        type="remove"
        onClick={handleClick}
        className={className}
        disabled={disabled}
      />
    );
  };

  render() {
    const {
      $id,
      schemaPath,
      children,
      multiple,
      statePath,
      title,
      length,
      helpText,
      computedStyles,
      errors
    } = this.props;
    // const labelStyles = `${styles.label} ${styles.defaultLabel}`;
    const hidden = get(this.props, 'layout.display') === 'none';
    const showAddButton = !hidden && (multiple || !length);
    return (
      <div
        className={`${computedStyles.base}`}
        data-group={statePath}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        {title ? <h2 className={computedStyles.title}>{title}</h2> : null}
        <p className={computedStyles.headerText}>
          {helpText ? <span>{helpText}</span> : null}
          {(errors || []).map((msg, i) => (
            <span key={`error-${i}`} className={computedStyles.error}>
              {msg}
            </span>
          ))}
        </p>
        {React.Children.map(children, this.renderInstance)}
        {showAddButton ? this.renderAddButton() : null}
      </div>
    );
  }
}

export default compose(
  // Ensures styles receive correct default props
  defaultProps(InputGroup.defaultProps),
  withStyles(stylesGenerator)
)(InputGroup);
