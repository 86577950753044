import {
  QUEUE_MASTER_CASES,
  QUEUE_CASES_TO_REVIEW,
  QUEUE_ADVERSE_EVENT,
  QUEUE_MEDICAL_INQUIRY,
  QUEUE_PRODUCT_QUALITY,
  QUEUE_YOUR_CASES
} from './routing';

export const GENERIC_STATE_PATHS = {
  DESCRIPTION: 'summary.narrative.long2'
};

export const SUBCASE_TYPE_PATHS = {
  ae: 'summary.narrative.categories.adverse_event',
  mi: 'summary.narrative.categories.medical_inquiry',
  pq: 'summary.narrative.categories.product_quality'
};

export const MASTER_STATE_PATHS = {
  ID: 'subcases.productQuality.id',
  PRODUCT_NAME: 'tracking.productName',
  PRODUCT_GROUPING: 'tracking.product_grouping',
  AWARENESS_DATE_AE: 'summary.awareness_date',
  AWARENESS_DATE_PQ: 'summary.productQuality.awareness_date',
  METHOD_OF_RECEIPT: 'tracking.methodOfReceipt',
  COUNTRY_OF_REPORTER: 'countryOfPrimaryReporter',
  AFFILIATE: 'affiliate',
  AFFILIATE_COUNTRY_LABEL: 'affiliateCountryLabel',
  SERIOUSNESS: 'summary.narrative.seriousness',
  SUMMARY_AFFILIATE_AWARENESS_DATE: 'summary.affiliate_awareness_date',
  TRILOGYLOADDATE: 'trilogyLoadDate',
  BATCH_LOT_NUMBER:
    'subcases.adverseEvent.product_section.aeproducts[0].product_therapy[0].batch_lot_no',
  CONTACT_TYPE: 'contacts.contact[0].type',
  PATIENT_REPORTER: 'patient.patient[0].reporter',
  SERVICE_PROVIDER: 'contacts.contact[0].psp.name',
  SERVICE_PROVIDER_FILTER: 'contacts.contact.psp.name',
  PROGRAM_ID_NAME: 'contacts.contact[0].psp.opi_number',
  PROGRAM_ID_NAME_FILTER: 'contacts.contact.psp.opi_number',
  PROGRAM_MARKETED_NAME:
    'subcases.adverseEvent.product_section.aeproducts[0].product_summary[0].local_trade_name',
  PATIENT_DOB: 'patient.patient[0].dob',
  PATIENT_DOB_FILTER: 'patient.patient.dob',
  PATIENT_INITIALS: 'patient.patient[0].initials',
  CONTACT_REFERENCE_NUMBER: 'contacts.contact[0].source_reference',
  REACTIONS: 'subcases.adverseEvent.reactions.reaction[0].adverse_event',
  DUEDATE: 'subcases.adverseEvent.aerinfo.safety.submission_due_date',
  HIGH_IMPACT:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.highImpact',
  PQ_OWNER: 'subcases.productQuality.assigneeName',
  REPLACEMENT_REQUIRED:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.replacement',
  SAMPLE_AVAILABLE:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.available',
  SAMPLE_REQUIRED_FOR_INVESTIGATION:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.sampleRequiredForInvestigation',
  DEATH: 'subcases.adverseEvent.reactions.reaction[0].serious_criteria.death',
  ADVERSEEVENT_SAFETY_SERIOUSNESS:
    'subcases.adverseEvent.aerinfo.safety.seriousness',
  OWNER: 'assigneeName',
  AUTOMATION_STATUS: 'automationStatus',
  PRODUCT_TYPE: 'subcases.adverseEvent.productType',
  TRANSLATION_STATUS: 'translationStatus',
  SENT_FOR_TRANSLATION: 'sentForTranslation',
  DOCUMENT_PENDING_TRANSLATION: 'documentPendingTranslation',
  DOCUMENT_SENT_DATE: 'documentSentDate',
  TRANSLATION_NA: 'N/A'
};

export const SERIOUSNESS_OPTIONS = {
  options: [
    { label: 'Serious', value: 'serious' },
    { label: 'Non Serious', value: 'nonserious' }
  ]
};

export const REPLACEMENT_REQUIRED_OPTIONS = {
  options: [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    },
    {
      label: 'No - Credit Only',
      value: 'creditOnly'
    },
    {
      label: 'Urgent Replacement',
      value: 'urgentReplacement'
    }
  ]
};

export const CUSTOMER_RESPONSE_REQUIRED_OPTIONS = {
  options: [
    { label: 'Yes', value: 'yes' },
    {
      label: 'No, customer declined letter',
      value: 'noCustomerDeclinedLetter'
    },
    {
      label: 'No, customer declined letter / training offered',
      value: 'noCustomerDeclinedLetterTrainingOffered'
    },
    {
      label: 'No, patient contact information or response unavailable',
      value: 'noPatientContactInfoUnavailable'
    },
    { label: 'No, Ex-US', value: 'noExUS' },
    {
      label: 'No, Complaint was not received via live call from the customer',
      value: 'noNonLiveCall'
    }
  ]
};

export const SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS = {
  options: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: 'Unknown', value: 'unknown' }
  ]
};

export const GENERIC_OPTIONS = {
  options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]
};

export const PRODUCT_TYPE_OPTIONS = {
  options: [
    { label: 'AGN', value: 'AGN' },
    { label: 'ABV', value: 'ABV' },
    { label: 'Both', value: 'ABV_AGN' }
  ]
};

export const RECORDS_COUNT_DOWNLOAD_CSV = 1000;

export const RECORDS_COUNT_DOWNLOAD_CSV_ERROR = `Please limit your export to under ${RECORDS_COUNT_DOWNLOAD_CSV} records`;

export const AE_STATE_PATHS = {
  ID: 'subcases.adverseEvent.id',
  LOCAL_TRADE_NAME:
    'subcases.adverseEvent.product_section.aeproducts[0].product_summary.local_trade_name',
  PRODUCT_NAME_FILTER:
    'subcases.adverseEvent.product_section.aeproducts.product_summary.suspect_product',
  PRODUCT_NAME:
    'subcases.adverseEvent.product_section.aeproducts[0].product_summary[0].suspect_product',
  OTHER_PRODUCT_NAME:
    'subcases.adverseEvent.product_section.aeproducts[0].product_summary[0].other_product',
  LAST_RECEIVED_DATE: 'subcases.adverseEvent.aerinfo.safety.last_received_date',
  SUBMISSION_DUE_DATE:
    'subcases.adverseEvent.aerinfo.safety.submission_due_date',
  COUNTRY_OF_REPORTER:
    'subcases.adverseEvent.aerinfo.safety.country_of_reporter',
  COUNTRY_OF_REPORTER_LABEL:
    'subcases.adverseEvent.aerinfo.safety.country_of_reporter_label',
  CASE_TYPE: 'subcases.adverseEvent.aerinfo.safety.version',
  STATUS: 'subcases.adverseEvent.status',
  SUBMITTED: 'subcases.adverseEvent.submitted',
  ASSIGNEE: 'subcases.adverseEvent.assignee',
  REACTIONS: 'subcases.adverseEvent.reactions.reaction[0].adverse_event',
  ABBVIE_AWARENESS_DATE: 'summary.awareness_date',
  PRODUCT_TYPE: 'subcases.adverseEvent.productType'
};

export const MI_STATE_PATHS = {
  ID: 'subcases.medicalInfo.id',
  CREATED: 'subcases.medicalInfo.createdDate',
  PRODUCT_NAME: 'subcases.medicalInfo.request[0].productName',
  STATUS: 'subcases.medicalInfo.status',
  ASSIGNEE: 'subcases.medicalInfo.assignee',
  COUNTRY_OF_REPORTER:
    'subcases.adverseEvent.aerinfo.safety.country_of_reporter'
};

export const PQ_STATE_PATHS = {
  ID: 'subcases.productQuality.id',
  PRODUCT_NAME_FILTER:
    'subcases.productQuality.pqproduct.products.details.marketedName',
  PRODUCT_NAME:
    'subcases.productQuality.pqproduct.products[0].details[0].marketedName',
  AWARENESS_DATE: 'summary.productQuality.awareness_date',
  STATUS: 'subcases.productQuality.status',
  ASSIGNEE: 'subcases.productQuality.assignee',
  COUNTRY_OF_REPORTER: 'subcases.productQuality.pqPrimaryCountry',
  HIGH_IMPACT:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.highImpact',
  SUBMITTED: 'subcases.productQuality.submitted',
  AFFILIATE_RETURN_SAMPLE_RECEIVED_DATE:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.affiliate_ret_sample_rec_date',
  BATCH_LOT_NUMBER:
    'subcases.productQuality.pqproduct.products[0].details[0].lotNumber',
  PRODUCT_MARKET_NAME:
    'subcases.productQuality.pqproduct.products[0].details[0].marketedName',
  PQ_OWNER: 'subcases.productQuality.assigneeName',
  CUSTOMER_RESPONSE_REQUIRED:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.letterRequired',
  PRODUCT: 'tracking.productName',
  SAMPLE_AVAILABLE_FORRETURN:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.available',
  SAMPLE_AVAILABLE: '',
  REPLACEMENT_REFERENCE_NUMBER:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.replacementReferenceNumber',
  LIST_NUMBER:
    'subcases.productQuality.pqproduct.products[0].details[0].listNumber',
  LIST_NUMBER_FILTER:
    'subcases.productQuality.pqproduct.products.details.listNumber',
  SOLTRAQS_REFERENCE_NUMBER:
    'subcases.productQuality.pqproduct.products[0].details[0].soltraqsNumber',
  REPLACEMENT_REQUIRED:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.replacement',
  SAMPLE_REQUIRED_FOR_INVESTIGATION:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.sampleRequiredForInvestigation',
  AFFILIATE_SAMPLE_SHIP_DATE:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.affiliate_sample_ship_date',
  HCP_REGULATORY_AGENCY_COMMUNICATION:
    'contacts.contact[0].pq.regulatory_agency_communication',
  PQ_LATEST_RECEIVED_DATE: 'subcases.productQuality.awareness_date',
  SAMPLE_TRACKING_TO_CUSTOMERS:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.sampleTrackingToCustomer',
  SUBMISSION_DATE_TO_SOLTRAQS: 'subcases.productQuality.submittedDate',
  HIGH_IMPACT_FILTER:
    'subcases.productQuality.pqproduct.products.complaint.processing.highImpact',
  REPLACEMENT_REQUIRED_FILTER:
    'subcases.productQuality.pqproduct.products.complaint.processing.replacement',
  SAMPLE_AVAILABLE_FILTER:
    'subcases.productQuality.pqproduct.products.complaint.processing.available',
  SAMPLE_REQUIRED_FOR_INVESTIGATION_FILTER:
    'subcases.productQuality.pqproduct.products.complaint.processing.sampleRequiredForInvestigation',
  AFFILIATE_RETURN_SAMPLE_RECEIVED_DATE_FILTER:
    'subcases.productQuality.pqproduct.products.complaint.processing.affiliate_ret_sample_rec_date',
  AFFILIATE_SAMPLE_SHIP_DATE_FILTER:
    'subcases.productQuality.pqproduct.products.complaint.processing.affiliate_sample_ship_date',
  CUSTOMER_RESPONSE_REQUIRED_FILTER:
    'subcases.productQuality.pqproduct.products.complaint.processing.letterRequired',
  METHOD_OF_RECEIPT: 'tracking.methodOfReceipt',
  FOLLOW_UP_REQUIRED:
    'subcases.productQuality.pqproduct.products[0].complaint.processing.followupRequired',
  RECONCILIATION: 'subcases.productQuality.reconciliation.wentIntoReconModeDate'
  
};

export const SUBCASE_STATE_PATH_MAP = {
  adverseEvent: AE_STATE_PATHS,
  medicalInfo: MI_STATE_PATHS,
  productQuality: PQ_STATE_PATHS
};

export const DROPDOWN_FILTER_PATHS = {
  masterCaseProduct: MASTER_STATE_PATHS.PRODUCT_NAME,
  suspectProduct: AE_STATE_PATHS.PRODUCT_NAME_FILTER,
  miProduct: MI_STATE_PATHS.PRODUCT_NAME,
  pqProduct: PQ_STATE_PATHS.PRODUCT_NAME_FILTER,
  caseCreators: 'creatorid',
  methodOfReceipt: MASTER_STATE_PATHS.METHOD_OF_RECEIPT,
  country: MASTER_STATE_PATHS.COUNTRY_OF_REPORTER,
  aeCountry: AE_STATE_PATHS.COUNTRY_OF_REPORTER,
  miCountry: MI_STATE_PATHS.COUNTRY_OF_REPORTER,
  pqCountry: PQ_STATE_PATHS.COUNTRY_OF_REPORTER,
  masterCaseSerious: MASTER_STATE_PATHS.SERIOUSNESS,
  seriousness: 'subcases.adverseEvent.aerinfo.safety.seriousness',
  caseType: AE_STATE_PATHS.CASE_TYPE,
  aeOwner: AE_STATE_PATHS.ASSIGNEE,
  miOwner: MI_STATE_PATHS.ASSIGNEE,
  pqOwner: PQ_STATE_PATHS.ASSIGNEE,
  aeSubmitted: AE_STATE_PATHS.SUBMITTED,
  pqHighImpact: PQ_STATE_PATHS.HIGH_IMPACT,
  caseOwner: 'assignee',
  followupRequired: PQ_STATE_PATHS.FOLLOW_UP_REQUIRED
};

export const DEFAULT_FILTER_STATE = {
  includeArchived: false,
  submitted: false,
  nonSubmitted: true,
  NEW: true,
  IN_PROGRESS: true,
  COMPLETED: false,
  AE: true,
  MI: true,
  PQ: true,
  masterCaseProduct: '',
  product: '',
  suspectProduct: '',
  miProduct: '',
  pqProduct: '',
  caseCreators: '',
  methodOfReceipt: '',
  country: '',
  aeCountry: '',
  seriousness: '',
  caseType: '',
  aeOwner: '',
  miOwner: '',
  pqOwner: '',
  caseOwner: '',
  dynamicFilters: {}
};
export const RESULTS_PER_PAGE = 100;

export const EXPORT_TO_CSV_BOOK_NAMES = {
  [QUEUE_MASTER_CASES]: 'Master Cases',
  [QUEUE_CASES_TO_REVIEW]: 'Cases To Review',
  [QUEUE_ADVERSE_EVENT]: 'Adverse Events',
  [QUEUE_MEDICAL_INQUIRY]: 'Medical Inquiry',
  [QUEUE_PRODUCT_QUALITY]: 'Product Complaints',
  [QUEUE_YOUR_CASES]: 'Your Cases'
};

export const EXPORT_TO_CSV_FILE_NAMES = {
  [QUEUE_MASTER_CASES]: 'Master_Cases',
  [QUEUE_CASES_TO_REVIEW]: 'Cases_To_Review',
  [QUEUE_ADVERSE_EVENT]: 'Adverse_Events',
  [QUEUE_MEDICAL_INQUIRY]: 'Medical_Inquiry',
  [QUEUE_PRODUCT_QUALITY]: 'Product_Complaints',
  [QUEUE_YOUR_CASES]: 'Your_Cases'
};

export const EXPORT_NO_FILTERS = 'No filters selected for this user';

export const FILTERS_QUEUE_PAGE = 'QUEUE FILTERS';

// Using ### as a seperator for multi select to overcome comma seperator problem.
export const CUSTOM_DELIMITER_DYMANIC_FILTERS = '###';
