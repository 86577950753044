import { get, isEmpty } from 'lodash';

import fileIcon from 'public/images/type-txt.svg';
import importIcon from 'public/images/import-icon.svg';
import editIcon from 'public/images/edit.svg';
import removeIcon from 'public/images/remove.svg';
import { generateCSS } from 'Common/components/Form';


const stylesGenerator = (theme) => ({
  

  listItemRow: generateCSS({
    alignItems: 'flex-start',
      display: 'flex',
      flex: '1 0 auto',
      width: '100%',
      flexWrap: 'nowrap',

    '&.with-padding': {
      padding: '10px'
    },

    '&.with-padding:first-child': {
      paddingLeft: '0px'
    },

    '&.with-padding:last-child': {
      paddingRight: '0px'
    }
  }),
  fileNameL: generateCSS({}),
  listItemColumn: generateCSS({
    alignItems: 'flex-start',
    display: 'flex',
    flex: '1 0 50%',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '50%'
  }),
  fileListItem: generateCSS({
    borderBottom: `1px solid ${theme.colors.primary}`,
    display: 'flex',
    padding: '15px 0',
    width: '100%',
    flexWrap: 'Wrap',
    // Document icon column
    '& > div:first-child > div:first-child': {
      maxWidth: '45px',
      marginRight: '10px'
    },
    // File info column
    '& > div:first-child > div + div': {
      flex: '1 1 auto',
      width: '100%'
    },
    // File info column - file name
    '& > div:first-child > div + div > span > a': {
      lineHeight: '2em',
      color: theme.colors.secondary
    },
    // Document icon
    '& > div:first-child > div > i': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '40px',
      textIndent: 5,
      position: 'relative',

      backgroundImage: `url("${fileIcon}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      height: 40,
      width: 50
    },

    '& > div:first-child > div > i > span': {
      backgroundColor: theme.colors.base,
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 8,
      textIndent: -2
    }
  }),
  fileList: generateCSS({
    padding: 0,
    margin: 0,
    listStyle: 'none',
    width: '100%'
  }),

  listChekbox: generateCSS({
    
    display: 'flex',
    alignItems: 'center', // Vertically centers the checkbox
    marginLeft: 'auto' // This will push it to the end
    
  }),
  customerShipNumber: generateCSS({
    padding: '15px'
    
  }),
  editItemContainer: generateCSS({
    display: 'flex',
    justifyContent: 'flex-end', // Aligns the checkbox to the right
    width: '100%' // Ensures it takes the full width
    // // alignItems: 'flex-start',
    // display: 'flex',
    // // // flex: '1 0 auto',
    // flexDirection: 'row',
    // justifyContent: 'flex-end',
    // width: '100%',

    // // '&> div': {
    //   // width: '100%'
    // // },

    // // pointerEvents: props.disabled ? 'none' : 'all',
    // // opacity: props.disabled ? '0.5' : '1',
    // // cursor: props.disabled ? 'not-allowed' : 'initial'
  }),
   
    
  
});

export default stylesGenerator;
