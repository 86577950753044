/* eslint no-useless-escape:0 no-template-curly-in-string:0 */
module.exports = {
  path: 'ae',
  title: 'Adverse Event',
  component: 'PropTypeSafety',
  tabs: [
    {
      title: 'AER Info',
      component: 'Tab',
      path: 'aerinfo',
      reconciliationPaths: [
        'subcases.adverseEvent.aerinfo.safety',
        'subcases.adverseEvent.aerinfo.study'
      ],
      descend: true,
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'aerinfo' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'safety',
          component: 'Section',
          statePath: 'subcases.adverseEvent.aerinfo.safety',
          title: 'Safety Report',

          elements: [
            {
              $ref: 'CountryList',
              statePath: 'country_of_reporter',
              label: 'Country of Primary Reporter',
              layout: {
                width: '50%'
              },
              validations: {
                required: {
                  mandate: true,
                  errorMessage: ''
                }
              }
            },
            {
              $ref: 'CountryList',
              statePath: 'country_of_detection',
              label: 'Country of Detection',
              layout: {
                width: '50%'
              },
              validations: {
                // required: {
                //   constraint: true,
                //   errorMessage: ''
                // }
                required: {
                  mandate: true,
                  errorMessage: ''
                }
              }
            },
            {
              component: 'Select',
              statePath: 'seriousness',
              label: 'Seriousness',
              placeholder: 'Select seriousness',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              styles: {
                width: '100%'
              },
              validations: {
                required: {
                  errorMessage: '',
                  constraint: true
                  // queryConstraint: {
                  //   schemaPath: './',
                  //   relative: true,
                  //   $if: [
                  //     {
                  //       $or: [
                  //         { value: { $eq: 'nonserious' } },
                  //         {
                  //           $and: [
                  //             { value: { $eq: 'serious' } },
                  //             {
                  //               $count: {
                  //                 'model.subcases.adverseEvent.reactions.reaction': {
                  //                   $filter: {
                  //                     reaction_seriousness: { $eq: 'serious' }
                  //                   }
                  //                 }
                  //               }
                  //             }
                  //           ]
                  //         }
                  //       ]
                  //     }
                  //   ],
                  //   $else: { $set: false }
                  // }
                }
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.seriousness'
                },
                disabled: {
                  statePath: 'subcases.adverseEvent.reactions.reaction',
                  $if: [
                    {
                      $compose: [
                        {
                          $count: {
                            $filter: {
                              reaction_seriousness: {
                                $eq: 'serious'
                              }
                            }
                          }
                        },
                        {
                          $gt: 0
                        }
                      ]
                    },
                    {
                      $set: 'serious'
                    }
                  ],
                  $else: {
                    $get: 'seriousness'
                  }
                },
                // value: {
                //   $if: [
                //     {
                //       'subcases.adverseEvent.reactions.reaction': {
                //         $count: {
                //           $filter: {
                //             reaction_seriousness: {
                //               $eq: 'serious'
                //             }
                //           }
                //         }
                //       }
                //     },
                //     {
                //       $set: 'serious'
                //     }
                //   ],
                //   $else: {
                //     $get: 'subcases.adverseEvent.aerinfo.safety.seriousness'
                //   }
                // },
                'layout.marginRight': {
                  relative: true,
                  statePath: 'seriousness',
                  $if: [{ $eq: 'nonserious' }, { $set: '0' }],
                  $else: { $set: '50%' }
                }
                // 'validations.required.errorMessage': {
                //   schemaPath: './',
                //   $if: [
                //     {
                //       'model.subcases.adverseEvent.aerinfo.safety.seriousness': {
                //         $eq: null
                //       }
                //     },
                //     {
                //       $set: 'A seriousness value is required'
                //     }
                //   ],
                //   $else: [
                //     {
                //       'model.subcases.adverseEvent.aerinfo.safety.seriousness': {
                //         $eq: 'serious'
                //       }
                //     },
                //     {
                //       'model.subcases.adverseEvent.reactions.reaction': {
                //         $not: {
                //           $count: {
                //             $filter: {
                //               reaction_seriousness: { $eq: 'serious' }
                //             }
                //           }
                //         }
                //       }
                //     },
                //     {
                //       $set:
                //         'A serious case must have at least one serious reaction'
                //     }
                //   ]
                // }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'Checkbox',
              statePath: 'expedited',
              label: 'Does this require expedited processing?',
              layout: {
                padding: 15,
                width: '50%'
              },
              referencedProperties: {
                'layout.display': {
                  statePath: 'subcases.adverseEvent.aerinfo.safety.seriousness',
                  $if: [{ $eq: 'nonserious' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              component: 'DateInput',
              statePath: 'last_received_date',
              label: 'Latest Received Date',
              validations: {
                required: {
                  constraint: true,
                  errorMessage: ''
                },
                date: {
                  constraint: 'full',
                  errorMessage: 'Full date required'
                },
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              label: 'Affiliate Awareness Date - Adverse Event',
              component: 'DateInput',
              statePath: 'affiliate_awareness_date',
              validations: {
                required: {
                  mandate: true,
                  errorMessage: ''
                },
                date: {
                  constraint: 'full',
                  errorMessage: 'Full date required'
                },
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              component: 'RadioGroup',
              statePath: 'version',
              // label:"*",
              options: [
                { label: 'Initial', value: 'initial_case' },
                { label: 'Follow-up', value: 'follow_up' }
              ],

              layout: {
                width: '50%',
                marginRight: '50%'
              },
              styles: {
                width: '100%'
              },

              validations: {
                required: {
                  mandate: true,
                  errorMessage: ''
                }
              },
              referencedProperties: {
                'layout.marginRight': {
                  $if: [
                    {
                      $or: [
                        {
                          'subcases.adverseEvent.aerinfo.safety.version': {
                            $eq: 'follow_up'
                          }
                        },
                        {
                          'summary.narrative.adverse_event.type': {
                            $eq: 'follow_up'
                          }
                        }
                      ]
                    },
                    { $set: '0' }
                  ],
                  $else: { $set: '50%' }
                }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'TextInput',
              label: 'Global Case ID',
              statePath: 'aer_rct_no',
              layout: {
                width: '50%'
              },
              validations: {
                required: {
                  mandate: true,
                  errorMessage: ''
                },
                maxLength: {
                  constraint: 30,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              },

              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: 'version',
                  $if: [{ $eq: 'follow_up' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              label: 'another case source',
              component: 'InputGroup',
              statePath: 'source',
              multiple: true,
              collapsible: false,
              length: 1,
              layout: {
                display: 'block'
              },
              elements: [
                {
                  component: 'Select',
                  statePath: 'sources',
                  label: 'Sources',
                  placeholder: 'Select answer',
                  options: [],
                  layout: {
                    width: '50%',
                    ' > div': {
                      display: 'flex',
                      flexDirection: 'column',
                      ' > div': {
                        order: 1
                      },
                      ' > div:last-child': {
                        order: 0
                      }
                    }
                  },
                  styles: {
                    width: '100%'
                  },
                  validations: {
                    required: {
                      mandate: true,
                      errorMessage: ''
                    }
                    // sourceMismatch: {
                    // queryConstraint: {
                    //   schemaPath: './',
                    //   $or: [
                    //     {
                    //       'data.submitted': {
                    //         $ne: true // not submitted = passing
                    //       }
                    //     },
                    //     {
                    //       '../index': {
                    //         $gt: 0 // only showing error on first source, if index > 0, this validation passes
                    //       }
                    //     },
                    //     // {
                    //     //   // return TRUE (pass validation) if `methodOfReceipt` is NOT `literature`
                    //     //   'model.tracking.methodOfReceipt': {
                    //     //     $ne: 'literature'
                    //     //   }
                    //     // },
                    //     // {
                    //     //   // If there WAS a `methodOfReceipt` set to `literature` validate `sources`
                    //     //   $count: {
                    //     //     'model.subcases.adverseEvent.aerinfo.safety.source': {
                    //     //       $filter: {
                    //     //         sources: {
                    //     //           $eq: 'Literature'
                    //     //         }
                    //     //       }
                    //     //     }
                    //     //   }
                    //     // }
                    //   ]
                    // },
                    // errorMessage:
                    //   'Must have a Source of "Literature" if Method of Receipt is "Literature"'
                    // }
                  },
                  referencedProperties: {
                    options: {
                      schemaPath: './',
                      $get: 'data.document-data.ae-options.safety_source'
                    },
                    // Clinical Study
                    'options[1].disabled': {
                      statePath: 'subcases.adverseEvent.aerinfo.safety.source',
                      $compose: [
                        {
                          $count: {
                            $filter: {
                              sources: {
                                $or: [
                                  { $eq: 'Spontaneous' },
                                  { $eq: 'Solicited' }
                                ]
                              }
                            }
                          }
                        },
                        {
                          $gt: 0
                        }
                      ]
                    },
                    // Solicited
                    'options[23].disabled': {
                      statePath: 'subcases.adverseEvent.aerinfo.safety.source',
                      $compose: [
                        {
                          $count: {
                            $filter: {
                              sources: {
                                $or: [
                                  { $eq: 'Spontaneous' },
                                  { $eq: 'Clinical Study' }
                                ]
                              }
                            }
                          }
                        },
                        {
                          $gt: 0
                        }
                      ]
                    },
                    // Spontaneous
                    'options[24].disabled': {
                      statePath: 'subcases.adverseEvent.aerinfo.safety.source',
                      $compose: [
                        {
                          $count: {
                            $filter: {
                              sources: {
                                $or: [
                                  { $eq: 'Solicited' },
                                  { $eq: 'Clinical Study' }
                                ]
                              }
                            }
                          }
                        },
                        {
                          $gt: 0
                        }
                      ]
                    },
                    // E2B Health Authority
                    'options[5].disabled': {
                      $set: true
                    },
                    // Health Authority
                    'options[8].disabled': {
                      statePath: 'subcases.adverseEvent.aerinfo.safety.source',
                      $compose: [
                        {
                          $count: {
                            $filter: {
                              sources: {
                                $eq: 'E2B Health Authority'
                              }
                            }
                          }
                        },
                        {
                          $gt: 0
                        }
                      ]
                    }
                  }
                  
                  // triggers: {
                  //   onFocus: 'onSafetySourcesDropdownFocus'
                  // }
                },
                {
                  component: 'TextInput',
                  label: 'Source Reference Number',
                  statePath: 'reference_number',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 50,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'Select',
                  label: 'Co-Licensing Partner',
                  statePath: 'co_licensing_partner',
                  layout: {
                    width: '50%'
                  },

                  options: [],

                  validations: {
                    // required: {
                    //   constraint: false,
                    //   errorMessage: ''
                    // }
                    required: {
                      mandate: true,
                      errorMessage: ''
                    }
                  },

                  referencedProperties: {
                    'layout.display': {
                      statePath: 'sources',
                      relative: true,
                      $if: [{ $eq: 'Co-Marketer' }, { $set: 'block' }], // If this value changes this ref prop for display will stop working
                      $else: { $set: 'none' }
                    },
                    // 'validations.required.constraint': {
                    //   statePath: 'sources',
                    //   relative: true,
                    //   $if: [{ $eq: 'Co-Marketer' }, { $set: true }], // If this value changes this ref prop required validation will stop working
                    //   $else: { $set: false }
                    // },
                    options: {
                      schemaPath: './',
                      $get:
                        'data.document-data.co-licensing-partner-options.co-licensing-partner-options'
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'DateInput',
                  label: 'Co-Licensing Partner Awareness Date',
                  statePath: 'partner_awareness_date',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    date: {
                      constraint: 'full',
                      errorMessage: 'Full date required'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    },
                    // required: {
                    //   constraint: false,
                    //   errorMessage: ''
                    // }
                    required: {
                      mandate: true,
                      errorMessage: ''
                    }
                  },

                  referencedProperties: {
                    'layout.display': {
                      statePath: 'sources',
                      relative: true,
                      $if: [{ $eq: 'Co-Marketer' }, { $set: 'block' }], // If this value changes this ref prop for display will stop working
                      $else: { $set: 'none' }
                    }
                    //   'validations.required.constraint': {
                    //     statePath: 'sources',
                    //     relative: true,
                    //     $if: [{ $eq: 'Co-Marketer' }, { $set: true }], // If this value changes this ref prop required validation will stop working
                    //     $else: { $set: false }
                    //   }
                  }
                },
                {
                  component: 'TextInput',
                  label: 'Co-Marketer API Key',
                  statePath: 'co_marketer_api_key',
                  layout: {
                    display: 'none'
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'Select',
                  label: 'Health Authority Name',
                  statePath: 'health_authority_name',
                  layout: {
                    width: '50%',
                    marginRight: '50%'
                  },

                  options: [],

                  referencedProperties: {
                    'layout.display': {
                      statePath: 'sources',
                      relative: true,
                      $if: [{ $eq: 'Health authority' }, { $set: 'block' }], // If this value changes this ref prop for display will stop working
                      $else: { $set: 'none' }
                    },
                    options: {
                      schemaPath: './',
                      $get:
                        'data.document-data.co-licensing-partner-options.health-authority-options'
                    }
                  }
                },
                {
                  component: 'TextInput',
                  label: 'Health Authority API Key',
                  statePath: 'health_authority_api_key',
                  layout: {
                    display: 'none'
                  }
                }
              ]
            },
            {
              component: 'Select',
              statePath: 'documents',
              label: 'Additional documents available?',
              placeholder: 'Select answer',
              options: [],
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              styles: {
                width: '100%'
              },

              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.generic_yes_no'
                },
                'layout.marginRight': {
                  statePath: 'subcases.adverseEvent.aerinfo.safety.documents',
                  $if: [{ $eq: 'yes' }, { $set: '0' }],
                  $else: { $set: '50%' }
                }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'TextInput',
              label: 'Additional Document Text',
              statePath: 'documents_text',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 2000,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              },

              referencedProperties: {
                'layout.display': {
                  statePath: 'subcases.adverseEvent.aerinfo.safety.documents',
                  $if: [{ $eq: 'yes' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              component: 'Select',
              statePath: 'md_contact_agree',
              label: 'Does the patient agree to have the MD contacted?',
              placeholder: 'Select answer',
              options: [],
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get:
                    'data.document-data.mastercase-options.generic_na_unknown'
                }
              }
            },
            {
              component: 'TextArea',
              statePath: 'sender_comments',
              label: 'Sender Comments',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              styles: {
                height: '176px',
                width: '100%'
              },
              validations: {
                maxLength: {
                  constraint: 2000,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            }
          ]
        },
        {
          id: 'study',
          component: 'Section',
          statePath: 'subcases.adverseEvent.aerinfo.study',
          title: 'Study',

          referencedProperties: {
            'layout.display': {
              statePath: 'subcases.adverseEvent.aerinfo.safety.source',
              $if: [
                {
                  $count: {
                    $filter: {
                      sources: {
                        $or: [{ $eq: 'Clinical Study' }, { $eq: 'Solicited' }]
                      }
                    }
                  }
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          },

          elements: [
            {
              label: 'LOOK UP PROTOCOL',
              component: 'SmartButton',
              triggers: {
                onClick: 'onProtocolLookUp'
              },
              layout: {
                width: '30%',
                paddingRight: 0
              },
              styles: {
                appearance: 'none',
                backgroundColor: '#071D49',
                border: 'none',
                color: '#FFFFFF',
                width: '134px',
                height: '30px',
                fontFamily: 'Calibri, "Microsoft Sans Serif"',
                fontSize: '14px',
                float: 'right'
              }
            },
            {
              label: 'REMOVE PROTOCOL',
              component: 'SmartButton',
              triggers: {
                onClick: 'onProtocolLookupClear'
              },
              layout: {
                width: '20%'
              },
              styles: {
                appearance: 'none',
                backgroundColor: '#FFFFFF',
                border: '1px solid #0082BA',
                color: '#0082BA',
                width: '134px',
                height: '30px',
                fontFamily: 'Calibri, "Microsoft Sans Serif"',
                fontSize: '14px',
                float: 'right'
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'TextInput',
              helpText: 'Add protocol using the protocol look up',
              label: 'Protocol Number',
              statePath: 'protocol',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-protocol-data.options'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'TextArea',
              label: 'Study Title',
              statePath: 'study_title',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              styles: {
                height: '176px',
                width: '100%'
              },
              validations: {
                maxLength: {
                  constraint: 240,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'Select',
              label: 'Study Design',
              statePath: 'study_design',
              placeholder: 'Select answer',
              options: [],
              layout: {
                width: '50%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.study_design'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'Checkbox',
              statePath: 'observational',
              label: 'Observational?',
              layout: {
                padding: 15,
                width: '100%',
                marginRight: '100%'
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'TextInput',
              label: 'Investigator Number',
              statePath: 'investigator',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 20,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'Select',
              label: 'Study Type',
              statePath: 'study_type',
              placeholder: 'Select answer',
              options: [],
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.study_type'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'TextInput',
              label: 'Study Patient Number',
              statePath: 'study_patient',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 20,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              },
              referencedProperties: {
                disabled: {
                  statePath: 'patient.patient[0].reporter',
                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                },
                helpText: {
                  statePath: 'patient.patient[0].reporter',
                  $if: [
                    { $ne: null },
                    {
                      $set:
                        'Cannot have both Third Party Patient ID and Study Patient Number populated'
                    }
                  ],
                  $else: { $set: '' }
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'Select',
              label: 'Study Phase',
              statePath: 'study_phase',
              placeholder: 'Select answer',
              options: [],
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.study_phase'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'TextInput',
              label: 'Local Study Number',
              statePath: 'study_local',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 25,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'TextInput',
              label: 'EUDRACT Number',
              statePath: 'eudract',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 14,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            }
          ]
        },
        {
          $ref: 'DocumentUploader'
        },
        {
          $ref: 'AESaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Contacts',
      component: 'Tab',
      path: 'contacts',
      reconciliationPaths: ['contacts.contact', 'patient.patient'],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'contacts' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'Contact',
          component: 'Section',
          title: 'Case Contacts',
          statePath: 'contacts',
          elements: [
            {
              $ref: 'LookupContactButton'
            },
            {
              $ref: 'ContactList',
              statePath: 'contact',
              label: 'another contact',
              length: 1,
              validations: {
                // required: {
                //   queryConstraint: {
                //     schemaPath: './',
                //     $if: [
                //       {
                //         value: {
                //           $count: {
                //             $filter: {
                //               'reporter.primary': {
                //                 $eq: true
                //               }
                //             }
                //           }
                //         }
                //       },
                //       {
                //         $set: true
                //       }
                //     ],
                //     $elseIf: {
                //       $set: { instance: { $eq: true } }
                //     },
                //     $else: {
                //       $set: false
                //     }
                //   },
                //   errorMessage:
                //     'At least one contact must be a Primary Reporter / Requester'
                // }
              }
            }
          ]
        },
        {
          id: 'patient',
          component: 'Section',
          title: 'Patient Information',
          statePath: 'patient',

          elements: [
            {
              $ref: 'LookupPatientButton'
            },
            {
              $ref: 'PatientInfo',
              statePath: 'patient'
            }
          ]
        },
        {
          $ref: 'AESaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Reactions',
      component: 'Tab',
      path: 'reactions',
      descend: true,
      reconciliationPaths: [
        'subcases.adverseEvent.reactions',
        'subcases.adverseEvent.death_details'
      ],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'reactions' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'reaction_section',
          component: 'Section',
          statePath: 'subcases.adverseEvent.reactions',
          title: 'Reactions',
          elements: [
            {
              component: 'InputGroup',
              label: 'adverse event description',
              statePath: 'description',
              multiple: false,
              collapsible: false,
              length: 0,
              layout: {
                width: '100%'
              },
              referencedProperties: {
                length: {
                  schemaPath: './',
                  relative: true,
                  $if: [
                    {
                      $compose: [{ $get: 'length' }, { $gt: 1 }]
                    },
                    { $set: 1 }
                  ],
                  $else: {
                    $set: 1
                  }
                }
              },
              elements: [
                {
                  component: 'TextArea',
                  label: '',
                  statePath: 'value',
                  layout: {
                    width: '100%'
                  },
                  styles: {
                    height: '240px',
                    width: '100%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 20000,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                }
              ]
            },
            {
              id: 'reactionItem',
              component: 'InputGroup',
              statePath: 'reaction',
              label: '',
              addButtonLabel: 'Add another reaction',
              multiple: true,
              collapsible: true,
              length: 1,
              layout: {
                display: 'block'
              },
              referencedProperties: {
                label: {
                  relative: true,
                  statePath: './',
                  $if: [
                    { adverse_event: { $ne: null } },
                    { $get: 'adverse_event' }
                  ],
                  $else: {
                    $set: 'Reaction ${order}'
                  }
                },
                collapsed: {
                  schemaPath: './',
                  relative: true,
                  $if: [
                    { collapsed: { $eq: null } },
                    {
                      $var: ['reactionLength', { $get: '../length' }]
                    },
                    { order: { $lt: { $var: 'reactionLength' } } },
                    { $set: true }
                  ],
                  $else: {
                    $get: 'collapsed'
                  }
                }
              },
              elements: [
                {
                  component: 'TextInput',
                  label: 'Adverse Event',
                  statePath: 'adverse_event',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    required: {
                      mandate: true
                    },
                    maxLength: {
                      constraint: 100,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  component: 'RadioGroup',
                  label: 'Reaction Seriousness',
                  statePath: 'reaction_seriousness',
                  value: '', // default to no selection
                  options: [
                    { label: 'Non Serious', value: 'nonserious' },
                    { label: 'Serious', value: 'serious' }
                  ],
                  layout: {
                    width: '50%'
                  },
                  styles: {
                    width: '100%'
                  },
                  disabled: false,
                  validations: {
                    required: {
                      mandate: true
                    }
                  },
                  referencedProperties: {
                    changeValue: {
                      description:
                        'This is used to force the onChange trigger in RadioGroup component',
                      relative: true,
                      $if: [
                        {
                          'duration[0].duration_outcome': { $eq: 'Death' }
                        },
                        {
                          reaction_seriousness: {
                            $ne: 'serious'
                          }
                        },
                        { $set: 'serious' }
                      ],
                      $else: { $set: null }
                    },
                    'options[0].disabled': {
                      relative: true,
                      $or: [
                        { $get: 'serious_criteria.death' },
                        { $get: 'serious_criteria.life_threat' },
                        { $get: 'serious_criteria.hospital' },
                        { $get: 'serious_criteria.prolonged_hospital' },
                        {
                          $get: 'serious_criteria.disabling_incapacitating'
                        },
                        { $get: 'serious_criteria.cong_birth_anomaly' },
                        { $get: 'serious_criteria.other' },
                        {
                          'duration[0].duration_outcome': { $eq: 'Death' }
                        },
                        { $set: false }
                      ]
                    }
                  },
                  triggers: {
                    onChange: {
                      schemaPath: './',
                      relative: true,
                      $if: [
                        {
                          value: { $eq: 'serious' }
                        },
                        {
                          $or: [
                            {
                              
                              'model.subcases.adverseEvent.aerinfo.safety.seriousness':{
                                $eq: 'nonserious'
                              }
                            },
                            {
                             'model.subcases.adverseEvent.aerinfo.safety.seriousness': {
                                $eq: null
                              }
                            }
                          ]  
                            

                          
                        },
                        {
                          $set: [
                            'showConfirmModal',
                            {
                              title: 'Change in Seriousness',
                              text:
                                'The Case Seriousness will be updated based on your Reaction Seriousness Criteria selection.'
                            }
                          ]
                        }
                      ]
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'Section',
                  statePath: 'serious_criteria',
                  label: 'Seriousness Criteria',
                  validations: {
                    required: {
                      errorMessage: 'A value must be selected',
                      queryConstraint: {
                        relative: true,
                        $if: [
                          {
                            serious_criteria: {
                              $count: {
                                $filter: {
                                  $eq: true
                                }
                              }
                            }
                          },
                          {
                            $set: true
                          }
                        ],
                        $else: { $set: false }
                      }
                    }
                  },
                  layout: {
                    width: '100%',
                    ' > div > div:first-child > div': {
                      width: '100%'
                    },
                    ' > div > div > div': {
                      width: '50%',
                      margin: 0
                    }
                  },
                  styles: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  },
                  referencedProperties: {
                    'layout.display': {
                      statePath: 'reaction_seriousness',
                      relative: true,
                      $if: [{ $eq: 'serious' }, { $set: 'block' }],
                      $else: { $set: 'none' }
                    }
                  },
                  elements: [
                    {
                      statePath: 'death',
                      component: 'Checkbox',
                      label: 'Results in death',
                      triggers: {
                        onChange: 'onCheckboxSelected'
                      }
                    },
                    {
                      label: 'Life threatening',
                      statePath: 'life_threat',
                      component: 'Checkbox',
                      triggers: {
                        onChange: 'onCheckboxSelected'
                      }
                    },
                    {
                      label: 'Hospitalization',
                      statePath: 'hospital',
                      component: 'Checkbox',
                      triggers: {
                        onChange: 'onCheckboxSelected'
                      }
                    },
                    {
                      label: 'Prolonged hospitalization',
                      statePath: 'prolonged_hospital',
                      component: 'Checkbox',
                      triggers: {
                        onChange: 'onCheckboxSelected'
                      }
                    },
                    {
                      label: 'Disabling or incapacitating',
                      statePath: 'disabling_incapacitating',
                      component: 'Checkbox',
                      triggers: {
                        onChange: 'onCheckboxSelected'
                      }
                    },
                    {
                      label: 'Congenital anomaly or birth defect',
                      statePath: 'cong_birth_anomaly',
                      component: 'Checkbox',
                      triggers: {
                        onChange: 'onCheckboxSelected'
                      }
                    },
                    {
                      label: 'Other medically important condition',
                      statePath: 'other',
                      component: 'Checkbox',
                      triggers: {
                        onChange: 'onCheckboxSelected'
                      }
                    }
                  ]
                },
                {
                  $ref: 'CountryList',
                  label: 'Country of Detection',
                  statePath: 'country_of_detection',
                  layout: {
                    width: '50%'
                  }
                },
                {
                  component: 'Select',
                  statePath: 'medically_confirmed',
                  label: 'Medically Confirmed',
                  options: [],
                  layout: {
                    width: '50%',
                    position: 'relative'
                  },
                  styles: {
                    width: '100%'
                  },
                  referencedProperties: {
                    options: {
                      schemaPath: './',
                      $get:
                        'data.document-data.mastercase-options.generic_yes_no'
                    }
                  }
                },
                {
                  id: 'reaction_duration',
                  component: 'InputGroup',
                  label: '',
                  multiple: false,
                  length: 1,
                  collapsible: false,
                  statePath: 'duration',
                  elements: [
                    {
                      component: 'DateInput',
                      statePath: 'duration_start_date',
                      label: 'Start Date',
                      validations: {
                        date: {
                          constraint: 'partial',
                          errorMessage: ''
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      }
                    },
                    {
                      component: 'DateInput',
                      statePath: 'duration_end_date',
                      label: 'End Date',
                      layout: {
                        marginRight: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'partial',
                          errorMessage: ''
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      label: 'Outcome',
                      statePath: 'duration_outcome',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      options: [],
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.duration_outcome'
                        }
                      },
                      triggers: {
                        onChange: {
                          relative: true,
                          schemaPath: './',
                          $if: [
                            { value: { $eq: 'Death' } },
                            {
                              '../../../elements[1].value': {
                                $ne: 'serious'
                              }
                            },
                            {
                              $set: [
                                'showConfirmModal',
                                {
                                  title: 'Change in Seriousness',
                                  text:
                                    'Reactions with an outcome of death must have a Reaction Seriousness of Serious. Are you sure you want to change the Outcome to Death?'
                                }
                              ]
                            }
                          ]
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      label: 'Time to Onset',
                      statePath: 'duration_latency',
                      layout: {
                        width: '50%'
                      },
                      referencedProperties: {
                        'validations.required.constraint': {
                          statePath: 'latency_units',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      },
                      validations: {
                        pattern: {
                          constraint: '^\\d+$',
                          errorMessage: 'Please only enter numbers'
                        },
                        maxLength: {
                          constraint: 5,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'latency_units',
                      label: 'Units',
                      pdfLabel: 'Latency Units',
                      options: [],
                      layout: {
                        width: '25%',
                        marginRight: '25%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.duration_latency'
                        },
                        'validations.required.constraint': {
                          statePath: 'duration_latency',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      label: 'Duration',
                      statePath: 'duration',
                      layout: {
                        width: '50%'
                      },
                      referencedProperties: {
                        'validations.required.constraint': {
                          statePath: 'duration_units',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      },
                      validations: {
                        pattern: {
                          constraint: '^\\d+$',
                          errorMessage: 'Please only enter numbers'
                        },
                        maxLength: {
                          constraint: 5,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'duration_units',
                      label: 'Units',
                      pdfLabel: 'Duration Units',
                      options: [],
                      layout: {
                        width: '25%',
                        marginRight: '25%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.duration_units'
                        },
                        'validations.required.constraint': {
                          statePath: 'duration',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'Checkbox',
                      label: 'Was the event caused by a drug interaction?',
                      statePath: 'drug_interaction_cause',
                      layout: {
                        padding: 15,
                        width: '100%'
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 'death_details',
          component: 'Section',
          title: 'Details of Death',
          statePath: 'subcases.adverseEvent.death_details',
          referencedProperties: {
            'layout.display': {
              statePath: 'subcases.adverseEvent.reactions.reaction',
              $if: [
                {
                  $count: {
                    $filter: {
                      $or: [
                        {
                          'serious_criteria.death': {
                            $eq: true
                          }
                        },
                        {
                          'duration[0].duration_outcome': {
                            $eq: 'Death'
                          }
                        }
                      ]
                    }
                  }
                },
                { $set: 'block' }
              ],
              $else: { $set: 'none' }
            }
          },

          elements: [
            {
              $ref: 'DisplayValueToggleChild',
              component: 'DateInput',
              statePath: 'date',
              label: 'Date of Death',
              layout: {
                width: '50%'
              },
              validations: {
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'Select',
              statePath: 'had_autopsy',
              label: 'Was an autopsy performed?',
              options: [],
              layout: {
                width: '50%'
              },
              validations: {
                required: {
                  mandate: true,
                  errorMessage: ''
                }
              },
              
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.generic_unknown'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'DateInput',
              statePath: 'autopsy_date',
              label: 'Date of Autopsy',
              layout: {
                width: '50%'
              },
              validations: {
                date: {
                  constraint: 'full',
                  errorMessage: 'Full date required'
                },
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              component: 'TextArea',
              label: 'Results of Autopsy',
              statePath: 'results_of_autopsy',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 100,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              component: 'InputGroup',
              statePath: 'cause_of_death',
              label: 'another cause of death',
              multiple: true,
              collapsible: false,
              length: 1,
              elements: [
                {
                  $ref: 'DisplayValueToggleChild',
                  component: 'Select',
                  statePath: 'determined_cause',
                  label: 'Was this cause of death determined by autopsy?',
                  layout: {
                    marginRight: '50%',
                    width: '50%'
                  },
                  options: [],
                  referencedProperties: {
                    options: {
                      schemaPath: './',
                      $get: 'data.document-data.ae-options.determined_cause'
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggleChild',
                  component: 'TextArea',
                  label: 'Cause of Death',
                  statePath: 'results',
                  layout: {
                    display: 'block',
                    width: '50%',
                    marginRight: '50%',
                    ' + div': {
                      left: '0'
                    }
                  },
                  validations: {
                    maxLength: {
                      constraint: 50,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          $ref: 'AESaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Product',
      component: 'Tab',
      path: 'product',
      reconciliationPaths: ['subcases.adverseEvent.product_section'],
      descend: true,
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'product' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'product_section',
          component: 'Section',
          statePath: 'subcases.adverseEvent.product_section',
          title: 'Product',

          elements: [
            {
              id: 'AE Product',
              statePath: 'aeproducts',
              component: 'InputGroup',
              label: '',
              addButtonLabel: 'Add another product',
              multiple: true,
              length: 1,

              layout: {
                display: 'block'
              },

              referencedProperties: {
                label: {
                  relative: true,
                  statePath: './',
                  $or: [
                    {
                      $and: [
                        {
                          $or: [
                            {
                              'product_summary[0].product_flag': {
                                $ne: null
                              }
                            },
                            {
                              $get: 'product_summary[0].suspect_product'
                            },
                            {
                              $get: 'product_summary[0].other_product'
                            }
                          ]
                        },
                        {
                          $concat: [
                            {
                              $or: [
                                {
                                  $and: [
                                    {
                                      'product_summary[0].product_flag': {
                                        $eq: 'abbvie_suspect_product'
                                      }
                                    },
                                    { $set: 'Suspect' }
                                  ]
                                },
                                {
                                  $and: [
                                    {
                                      'product_summary[0].product_flag': {
                                        $eq: 'concomitant'
                                      }
                                    },
                                    { $set: 'Concomitant' }
                                  ]
                                },
                                {
                                  $and: [
                                    {
                                      'product_summary[0].product_flag': {
                                        $eq: 'treatment'
                                      }
                                    },
                                    { $set: 'Treatment' }
                                  ]
                                },
                                {
                                  $and: [
                                    {
                                      'product_summary[0].product_flag': {
                                        $eq: 'past'
                                      }
                                    },
                                    { $set: 'Past' }
                                  ]
                                },
                                { $set: '' }
                              ]
                            },
                            {
                              $or: [
                                {
                                  $and: [
                                    {
                                      'product_summary[0].product_flag': {
                                        $ne: null
                                      }
                                    },
                                    {
                                      $or: [
                                        {
                                          $get:
                                            'product_summary[0].suspect_product'
                                        },
                                        {
                                          $get:
                                            'product_summary[0].other_product'
                                        }
                                      ]
                                    },
                                    {
                                      $set: ' - '
                                    }
                                  ]
                                },
                                {
                                  $set: ' '
                                }
                              ]
                            },
                            {
                              $or: [
                                {
                                  $and: [
                                    {
                                      $or: [
                                        {
                                          $get:
                                            'product_summary[0].suspect_product'
                                        },
                                        {
                                          $get:
                                            'product_summary[0].other_product'
                                        },
                                        { $set: '' }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  $set: ''
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    { $set: 'Product ${order}' }
                  ]
                },
                collapsed: {
                  schemaPath: './',
                  relative: true,
                  $if: [
                    { collapsed: { $eq: null } },
                    {
                      $var: ['productLength', { $get: '../length' }]
                    },
                    { order: { $lt: { $var: 'productLength' } } },
                    { $set: true }
                  ],
                  $else: {
                    $get: 'collapsed'
                  }
                }
              },

              // validations: {
              //   required: {
              //     errorMessage:
              //       'At least one AbbVie Suspect Product required for submission.',
              //     queryConstraint: {
              //       relative: true,
              //       schemaPath: './',
              //       $if: [
              //         {
              //           $count: {
              //             'model.subcases.adverseEvent.product_section.aeproducts': {
              //               $filter: {
              //                 $and: [
              //                   {
              //                     'product_summary[0].product_flag': {
              //                       $eq: 'abbvie_suspect_product'
              //                     }
              //                   },
              //                   {
              //                     'product_summary[0].suspect_product': {
              //                       $ne: null
              //                     }
              //                   }
              //                 ]
              //               }
              //             }
              //           }
              //         },
              //         {
              //           $set: true
              //         }
              //       ],
              //       $else: {
              //         $set: false
              //       }
              //     }
              //   }
              // },

              elements: [
                {
                  id: 'product_summary',
                  statePath: 'product_summary',
                  component: 'InputGroup',
                  title: 'Product Summary',
                  multiple: false,
                  length: 1,
                  collapsible: false,

                  elements: [
                    {
                      component: 'Select',
                      statePath: 'product_flag',
                      label: 'Product Flag',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      options: [],
                      validations: {
                        required: {
                          mandate: true,
                          errorMessage: ''
                        }
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.product_flag'
                        }
                      }
                    },
                    {
                      label: 'LOOK UP PRODUCT',
                      component: 'SmartButton',
                      triggers: {
                        onClick: 'onProductLookUp'
                      },
                      layout: {
                        width: '50%',
                        marginRight: '50%',
                        display: 'flex',
                        flexDirection: 'row'
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#071D49',
                        border: 'none',
                        color: '#FFFFFF',
                        marginLeft: 'auto',
                        width: '134px',
                        height: '36px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '14px',
                        float: 'right'
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          statePath: 'other_product',
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        },
                        'layout.display': {
                          relative: true,
                          statePath: 'product_flag',
                          $if: [
                            {
                              $or: [
                                { $eq: 'concomitant' },
                                { $eq: 'treatment' },
                                { $eq: 'past' }
                              ]
                            },
                            { $set: 'none' }
                          ],
                          $else: { $set: 'block' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextInput',
                      helpText: 'Add product using the product look up',
                      statePath: 'suspect_product',
                      label: 'AbbVie Suspect Product',
                      layout: {
                        width: '50%',
                        display: 'block'
                      },
                      validations: {
                        required: {
                          mandate: true
                        },
                        maxLength: {
                          constraint: 70,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      disabled: true,
                      referencedProperties: {
                        // 'validations.required.constraint': {
                        //   relative: true,
                        //   $if: [
                        //     {
                        //       product_flag: {
                        //         $eq: 'abbvie_suspect_product'
                        //       }
                        //     },
                        //     {
                        //       other_product: {
                        //         $eq: null
                        //       }
                        //     },
                        //     { $set: true }
                        //   ],
                        //   $else: { $set: false }
                        // },
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              product_flag: {
                                $or: [
                                  { $eq: 'concomitant' },
                                  { $eq: 'treatment' },
                                  { $eq: 'past' }
                                ]
                              }
                            },
                            { $set: 'none' }
                          ],
                          $else: { $set: 'block' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextInput',
                      statePath: 'local_trade_name',
                      label: 'Local Trade Name (auto-populated)',
                      layout: {
                        width: '50%',
                        display: 'block'
                      },
                      validations: {
                        required: {
                          mandate: true,
                          errorMessage: ''
                        },
                        maxLength: {
                          constraint: 255,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        // 'validations.required.mandate': {
                        //   relative: true,
                        //   $if: [
                        //     {
                        //       product_flag: {
                        //         $eq: 'abbvie_suspect_product'
                        //       }
                        //     },
                        //     {
                        //       other_product: {
                        //         $eq: null
                        //       }
                        //     },
                        //     { $set: true }
                        //   ],
                        //   $else: { $set: false }
                        // },
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { product_flag: { $eq: 'concomitant' } },
                                { product_flag: { $eq: 'treatment' } },
                                { product_flag: { $eq: 'past' } }
                              ]
                            },
                            { $set: 'none' }
                          ],
                          $else: { $set: 'block' }
                        },
                        disabled: {
                          relative: true,
                          $if: [{ $get: 'other_product' }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextInput',
                      statePath: 'type',
                      label: 'Type',
                      layout: {
                        visibility: 'hidden',
                        height: '0px',
                        width: '0px',
                        padding: '0px'
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextInput',
                      statePath: 'other_product',
                      label: 'Other Product',
                      layout: {
                        width: '50%'
                      },
                      disabled: false,
                      validations: {
                        required: {
                          mandate: true,
                          errorMessage: ''
                        },
                        maxLength: {
                          constraint: 70,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        // 'validations.required.constraint': {
                        //   relative: true,
                        //   $if: [
                        //     {
                        //       $or: [
                        //         { product_flag: { $eq: 'concomitant' } },
                        //         { product_flag: { $eq: 'treatment' } },
                        //         { product_flag: { $eq: 'past' } },
                        //         {
                        //           $and: [
                        //             {
                        //               product_flag: {
                        //                 $eq: 'abbvie_suspect_product'
                        //               }
                        //             },
                        //             {
                        //               local_trade_name: {
                        //                 $eq: null
                        //               }
                        //             },
                        //             {
                        //               suspect_product: {
                        //                 $eq: null
                        //               }
                        //             }
                        //           ]
                        //         }
                        //       ]
                        //     },
                        //     { $set: true }
                        //   ],
                        //   $else: { $set: false }
                        // },
                        disabled: {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { $get: 'suspect_product' },
                                { $get: 'local_trade_name' }
                              ]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      label: 'REMOVE PRODUCT',
                      component: 'SmartButton',
                      triggers: {
                        onClick: 'onProductLookUpClear'
                      },
                      layout: {
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'row'
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #0082BA',
                        color: '#0082BA',
                        marginLeft: 'auto',
                        width: '134px',
                        height: '36px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '14px',
                        float: 'right'
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          $if: [
                            {
                              $or: [{ $get: 'other_product' }]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        },
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { product_flag: { $eq: 'concomitant' } },
                                { product_flag: { $eq: 'treatment' } },
                                { product_flag: { $eq: 'past' } }
                              ]
                            },
                            { $set: 'none' }
                          ],
                          $else: { $set: 'block' }
                        }
                      }
                    },
                    {
                      $ref: 'CountryList',
                      statePath: 'drug_obtain_country',
                      label: 'Drug Obtain Country',
                      layout: {
                        width: '50%',
                        display: 'block',
                        marginRight: '50%'
                      },
                      referencedProperties: {
                        value: {
                          relative: true,
                          schemaPath: './',
                          $if: [
                            { 'layout.display': { $eq: 'none' } },
                            { $set: null }
                          ],
                          $else: { $get: 'value' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'Select',
                      statePath: 'coding_class',
                      label: 'Coding Class',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      options: [],
                      validations: {
                        // required: {
                        //   constraint: false,
                        //   errorMessage: ''
                        // }
                        required: {
                          mandate: true,
                          errorMessage: ''
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'product_flag',
                          relative: true,
                          $if: [
                            {
                              $eq: 'abbvie_suspect_product'
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        },
                        // 'validations.required.constraint': {
                        //   statePath:
                        //     'subcases.adverseEvent.aerinfo.safety.source',
                        //   $compose: [
                        //     {
                        //       $count: {
                        //         $filter: {
                        //           sources: {
                        //             $eq: 'Clinical Study'
                        //           }
                        //         }
                        //       }
                        //     },
                        //     {
                        //       $gt: 0
                        //     }
                        //   ]
                        // },
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.coding_class'
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'Select',
                      statePath: 'action_taken',
                      label: 'Action Taken',
                      options: [],
                      layout: {
                        width: '50%',
                        marginRight: '50%',
                        display: 'block'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.action_taken'
                        },
                        'layout.display': {
                          statePath: 'product_flag',
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { $eq: 'concomitant' },
                                { $eq: 'treatment' },
                                { $eq: 'past' }
                              ]
                            },
                            { $set: 'none' }
                          ],
                          $else: { $set: 'block' }
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'dechallenge',
                      label: 'Dechallenge',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      options: [],
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.mastercase-options.generic_na_unknown'
                        },
                        pristine: {
                          schemaPath: './',
                          relative: true,
                          $set: { $not: { $get: 'initialize' } }
                        },
                        value: {
                          relative: true,
                          schemaPath: './',
                          $if: [
                            { 'layout.display': { $eq: 'none' } },
                            { $set: null }
                          ],
                          $elseIf: [
                            {
                              $or: [
                                {
                                  '../value.action_taken': {
                                    $eq: 'dosage_decreased'
                                  }
                                },
                                {
                                  '../value.action_taken': {
                                    $eq: 'dosage_increased'
                                  }
                                },
                                { '../value.action_taken': { $eq: 'unknown' } },
                                {
                                  '../value.action_taken': {
                                    $eq: 'dose_unchanged'
                                  }
                                },
                                { '../value.action_taken': { $eq: 'na' } }
                              ]
                            },
                            { $set: 'na' }
                          ],
                          $else: { $get: 'value' }
                        },
                        disabled: {
                          relative: true,
                          statePath: 'action_taken',
                          $if: { $ne: 'discontinued' },
                          $else: { $set: false }
                        },
                        'layout.display': {
                          statePath: 'product_flag',
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { $eq: 'concomitant' },
                                { $eq: 'treatment' },
                                { $eq: 'past' }
                              ]
                            },
                            { $set: 'none' }
                          ],
                          $else: { $set: 'block' }
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'rechallenge',
                      label: 'Rechallenge',
                      layout: {
                        width: '50%',
                        marginRight: '50%',
                        display: 'block'
                      },
                      options: [],
                      disabled: false,
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.rechallenge'
                        },
                        pristine: {
                          schemaPath: './',
                          relative: true,
                          $set: { $not: { $get: 'initialize' } }
                        },
                        'layout.display': {
                          statePath: 'product_flag',
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { $eq: 'concomitant' },
                                { $eq: 'treatment' },
                                { $eq: 'past' }
                              ]
                            },
                            { $set: 'none' }
                          ],
                          $else: { $set: 'block' }
                        },
                        disabled: {
                          relative: true,
                          $if: [
                            {
                              action_taken: {
                                $ne: 'discontinued'
                              }
                            }
                          ],
                          $elseIf: [
                            { dechallenge: { $ne: 'yes' } },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        },
                        value: {
                          schemaPath: './',
                          $if: [
                            {
                              $or: [
                                { 'layout.display': { $eq: 'none' } },
                                // dechallenge field within same summary group
                                {
                                  '../elements[10].value': {
                                    $ne: 'yes'
                                  }
                                },
                                {
                                  '../elements[10].disabled': {
                                    $eq: true
                                  }
                                }
                              ]
                            },
                            { $set: null }
                          ],
                          $else: {
                            $set: { $get: 'value' }
                          }
                        }
                      }
                    }
                  ]
                },
                {
                  id: 'product_indication',
                  statePath: 'product_indication',
                  component: 'InputGroup',
                  label: 'another indication',
                  multiple: true,
                  length: 1,
                  collapsible: false,
                  layout: {
                    width: '50%',
                    marginRight: '50%'
                  },
                  elements: [
                    {
                      component: 'TextInput',
                      statePath: 'drug_indications',
                      label: 'Drug Indication',
                      layout: {
                        width: '100%'
                      },
                      validations: {
                        maxLength: {
                          constraint: 100,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      }
                    }
                  ]
                },
                {
                  id: 'reporter_causality',
                  statePath: 'reporter_causality',
                  // links to reactions list
                  $link: 'elements[2].elements[0].elements[1]',
                  length: 1,
                  component: 'InputGroup',
                  title: 'Reporter Causality',
                  multiple: false,
                  collapsible: false,
                  styles: {
                    flexWrap: 'wrap'
                  },
                  referencedProperties: {
                    label: {
                      statePath:
                        'subcases.adverseEvent.reactions.reaction[${index}]',
                      $or: [
                        { $get: 'adverse_event' },
                        { $set: 'Reaction ${order}' }
                      ]
                    },
                    'layout.display': {
                      relative: true,
                      statePath: 'product_summary[0].product_flag',
                      $if: [
                        {
                          $eq: 'abbvie_suspect_product'
                        },
                        {
                          $set: 'block'
                        }
                      ],
                      $else: {
                        $set: 'none'
                      }
                    }
                  },
                  elements: [
                    {
                      $ref: 'DisplayValueToggleChild',
                      component: 'Select',
                      statePath: 'reporter_causality',
                      label: 'Causality as per Reporter',
                      options: [],
                      layout: {
                        width: '50%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.ae-options.causality_per_reporter'
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggleChild',
                      component: 'TextInput',
                      statePath: 'alternative_etiology',
                      label: 'Alternative Etiology - Reporter',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        maxLength: {
                          constraint: 200,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      }
                    }
                  ]
                },
                {
                  id: 'product_therapy',
                  statePath: 'product_therapy',
                  component: 'InputGroup',
                  title: 'Product Therapy',
                  label: '',
                  addButtonLabel: 'Add another product therapy',
                  multiple: true,
                  length: 1,
                  referencedProperties: {
                    label: {
                      relative: true,
                      statePath: './',
                      $set: 'Product Therapy ${order}'
                    }
                  },

                  elements: [
                    {
                      component: 'CopyProductTherapyButton',
                      label: '',
                      triggers: {
                        onClick: 'onCopyProductTherapy'
                      },
                      layout: {
                        width: '100%',
                        height: '1',
                        minHeight: 'unset'
                      },
                      styles: {
                        position: 'absolute',
                        width: 'fit-content',
                        height: '35px',
                        right: '-15px',
                        top: '20px',
                        zIndex: '9',
                        padding: '5px',
                        border: '0'
                      },
                      className: 'clone-product',
                      id: 'clone-product-therapy'
                    },
                    {
                      component: 'Section',
                      layout: {
                        // 2-line labels heighten container, aligns inputs to bottom
                        alignItems: 'flex-end',
                        display: 'flex',
                        padding: 0,
                        margin: 0
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          statePath: '../product_summary[0].product_flag',
                          $if: [
                            {
                              $or: [
                                {
                                  $eq: 'concomitant'
                                },
                                {
                                  $eq: 'treatment'
                                },
                                {
                                  $eq: 'past'
                                }
                              ]
                            },
                            { $set: 'none' }
                          ],
                          $else: { $set: 'flex' }
                        }
                      },
                      elements: [
                        {
                          component: 'TextInput',
                          statePath: 'batch_lot_no',
                          label: 'Batch/Lot Number',
                          layout: {
                            width: '50%'
                          },
                          validations: {
                            maxLength: {
                              constraint: 35,
                              errorMessage:
                                'The maximum number of characters has been reached'
                            }
                          },
                          referencedProperties: {
                            // Used to support AC defined in TRGY-1904. Value should clear on `null` flag
                            // but a new user value should be allowed with a `null` flag
                            lastProductFlag: {
                              relative: true,
                              statePath: '../product_summary[0].product_flag'
                            },
                            value: {
                              relative: true,
                              statePath: '../product_summary[0].product_flag',
                              $if: [
                                {
                                  $eq: 'abbvie_suspect_product'
                                },
                                {
                                  $this: { $get: '../visible' }
                                },
                                {
                                  $this: { $get: 'value' }
                                }
                              ],
                              $elseIf: [
                                {
                                  $if: [
                                    {
                                      $this: {
                                        lastProductFlag: {
                                          $ne: 'abbvie_suspect_product'
                                        }
                                      }
                                    },
                                    { $eq: null },
                                    {
                                      $this: {
                                        $get: 'value'
                                      }
                                    }
                                  ],
                                  $else: [
                                    {
                                      $this: {
                                        lastProductFlag: {
                                          $eq: 'abbvie_suspect_product'
                                        }
                                      }
                                    },
                                    {
                                      $eq: null
                                    },
                                    {
                                      $set: null
                                    }
                                  ]
                                }
                              ],
                              $else: { $set: null }
                            },
                            'layout.marginRight': {
                              statePath: 'availability.unavailable',
                              relative: true,
                              $if: [{ $eq: true }, { $set: '0' }],
                              $else: { $set: '50%' }
                            },
                            disabled: {
                              relative: true,
                              $if: [
                                {
                                  $this: {
                                    lastProductFlag: {
                                      $eq: null
                                    }
                                  }
                                },
                                {
                                  $this: {
                                    $ne: [
                                      { $get: 'lastProductFlag' },
                                      {
                                        $get:
                                          '../../../../elements[0].instances[0].elements[0].value'
                                      }
                                    ]
                                  }
                                },
                                {
                                  $this: {
                                    $get: 'disabled'
                                  }
                                }
                              ],
                              $elseIf: [
                                {
                                  $this: {
                                    $eq: [
                                      { $get: 'lastProductFlag' },
                                      {
                                        $get:
                                          '../../../../elements[0].instances[0].elements[0].value'
                                      }
                                    ]
                                  }
                                },
                                {
                                  $or: [
                                    { $get: 'availability.unavailable' },
                                    { $get: 'availability.unknown' }
                                  ]
                                },
                                {
                                  $set: true
                                }
                              ],
                              $else: {
                                $set: false
                              }
                            }
                          }
                        },
                        // Duplicate field from above but with validations (and new options?)
                        {
                          label: 'Why is the lot number unavailable?',
                          component: 'Select',
                          statePath: 'reason',
                          layout: {
                            width: '50%'
                          },
                          validations: {
                            required: {
                              constraint: true,
                              errorMessage: ''
                            }
                          },
                          referencedProperties: {
                            options: {
                              schemaPath: './',
                              $get:
                                'data.document-data.ae-options.ae_lot_number_unavailable_reason'
                            },
                            // Used to support AC defined in TRGY-1904. Value should clear on `null` flag
                            // but a new user value should be allowed with a `null` flag
                            lastProductFlag: {
                              relative: true,
                              statePath: '../product_summary[0].product_flag'
                            },
                            'layout.display': {
                              relative: true,
                              statePath: 'availability',
                              $if: [
                                {
                                  $this: {
                                    $and: [
                                      {
                                        lastProductFlag: {
                                          $eq: 'abbvie_suspect_product'
                                        }
                                      },
                                      {
                                        // Going up to the top-level product
                                        '../../../../elements[0].instances[0].elements[0].value': {
                                          $eq: null
                                        }
                                      }
                                    ]
                                  }
                                },
                                { $set: 'none' }
                              ],
                              $elseIf: [
                                {
                                  $this: {
                                    $and: [
                                      {
                                        // Going up to the top-level product
                                        '../../../../elements[0].instances[0].elements[0].value': {
                                          $or: [
                                            { $eq: 'abbvie_suspect_product' },
                                            { $eq: null }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  unavailable: {
                                    $eq: true
                                  }
                                },
                                {
                                  $set: 'block'
                                }
                              ],
                              $else: { $set: 'none' }
                            },
                            value: {
                              relative: true,
                              statePath: '../product_summary[0].product_flag',
                              $if: [
                                {
                                  $eq: 'abbvie_suspect_product'
                                },
                                {
                                  $this: {
                                    $and: [
                                      { $get: '../visible' },
                                      { $get: 'visible' }
                                    ]
                                  }
                                },
                                {
                                  $this: { $get: 'value' }
                                }
                              ],
                              $elseIf: [
                                {
                                  $if: [
                                    {
                                      $this: {
                                        $and: [
                                          {
                                            'layout.display': {
                                              $ne: 'none'
                                            }
                                          },
                                          {
                                            lastProductFlag: {
                                              $ne: 'abbvie_suspect_product'
                                            }
                                          }
                                        ]
                                      }
                                    },
                                    { $eq: null },
                                    {
                                      $this: {
                                        $get: 'value'
                                      }
                                    }
                                  ],
                                  $else: [
                                    {
                                      $this: {
                                        lastProductFlag: {
                                          $eq: 'abbvie_suspect_product'
                                        }
                                      }
                                    },
                                    {
                                      $eq: null
                                    },
                                    {
                                      $set: null
                                    }
                                  ]
                                }
                              ],
                              $else: { $set: null }
                            }
                          }
                        },
                        {
                          component: 'LabelValue',
                          label: '',
                          value: '',
                          layout: {
                            width: '50%',
                            marginLeft: '50%'
                          },
                          referencedProperties: {
                            lastProductFlag: {
                              relative: true,
                              statePath: '../product_summary[0].product_flag'
                            },
                            'layout.display': {
                              relative: true,
                              statePath: 'availability',
                              $if: [
                                {
                                  $this: {
                                    $and: [
                                      {
                                        lastProductFlag: {
                                          $eq: 'abbvie_suspect_product'
                                        }
                                      },
                                      {
                                        // Going up to the top-level product
                                        '../../../../elements[0].instances[0].elements[0].value': {
                                          $eq: null
                                        }
                                      }
                                    ]
                                  }
                                },
                                { $set: 'none' }
                              ],
                              $elseIf: [
                                {
                                  $this: {
                                    $and: [
                                      {
                                        // Going up to the top-level product
                                        '../../../../elements[0].instances[0].elements[0].value': {
                                          $or: [
                                            { $eq: 'abbvie_suspect_product' },
                                            { $eq: null }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  unavailable: {
                                    $eq: true
                                  }
                                },
                                {
                                  $set: 'block'
                                }
                              ],
                              $else: { $set: 'none' }
                            },
                            value: {
                              relative: true,
                              statePath: 'reason',
                              $or: [
                                {
                                  $if: [
                                    { $eq: 'packaging_discarded' },
                                    {
                                      $set:
                                        'Primary reporter does not have the lot number information, because the packaging was discarded.'
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'physician_inaccessible' },
                                    {
                                      $set:
                                        'Primary reporter does not have the lot number information, because the information is not accessible to the physician at his/her clinical setting.'
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'not_available' },
                                    {
                                      $set:
                                        "Primary reporter does not have the lot number information, because the information is not available in the patient's file."
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'not_original_packaging' },
                                    {
                                      $set:
                                        'Primary reporter does not have the lot number information, because the patient did not receive the product in its original package.'
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'not_legible' },
                                    {
                                      $set:
                                        'Primary reporter does not have the lot number information, because the lot number was not legible on product package.'
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'declined_to_provide' },
                                    {
                                      $set:
                                        'Primary reporter declined to provide lot number information.'
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'contact_unsuccessful' },
                                    {
                                      $set:
                                        'Primary reporter contact for lot number information was unsuccessful.'
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'contact_unavailable' },
                                    {
                                      $set:
                                        'Primary reporter’s contact information was not available.'
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'declined_contact' },
                                    {
                                      $set:
                                        'Primary reporter declined to be contacted.'
                                    }
                                  ]
                                },
                                {
                                  $if: [
                                    { $eq: 'other' },
                                    {
                                      $set:
                                        'Primary reporter does not have the lot number information, because...'
                                    }
                                  ]
                                },
                                { $set: ' ' }
                              ]
                            }
                          }
                        },
                        {
                          component: 'TextInput',
                          statePath: 'additional_comments',
                          label: 'Additional Comments',
                          layout: {
                            width: '50%',
                            margin: '0 0 0 50%'
                          },
                          validations: {
                            maxLength: {
                              constraint: 150,
                              errorMessage:
                                'The maximum number of characters has been reached'
                            }
                          },
                          referencedProperties: {
                            // Used to support AC defined in TRGY-1904. Value should clear on `null` flag
                            // but a new user value should be allowed with a `null` flag
                            lastProductFlag: {
                              relative: true,
                              statePath: '../product_summary[0].product_flag'
                            },
                            'layout.display': {
                              relative: true,
                              $if: [
                                {
                                  reason: {
                                    $eq: 'other'
                                  }
                                },
                                {
                                  'availability.unavailable': {
                                    $eq: true
                                  }
                                },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            value: {
                              relative: true,
                              statePath: '../product_summary[0].product_flag',
                              $if: [
                                {
                                  $eq: 'abbvie_suspect_product'
                                },
                                {
                                  $this: {
                                    $and: [
                                      {
                                        '../elements[1].value': {
                                          $eq: 'other'
                                        }
                                      },
                                      {
                                        'layout.display': {
                                          $ne: 'none'
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  $this: { $get: '../visible' }
                                },
                                {
                                  $this: { $get: 'value' }
                                }
                              ],
                              $elseIf: [
                                {
                                  $if: [
                                    {
                                      $this: {
                                        $and: [
                                          {
                                            '../elements[1].value': {
                                              $eq: 'other'
                                            }
                                          },
                                          {
                                            'layout.display': {
                                              $ne: 'none'
                                            }
                                          }
                                        ]
                                      }
                                    },
                                    {
                                      $this: {
                                        lastProductFlag: {
                                          $ne: 'abbvie_suspect_product'
                                        }
                                      }
                                    },
                                    { $eq: null },
                                    {
                                      $this: {
                                        $get: 'value'
                                      }
                                    }
                                  ],
                                  $else: [
                                    {
                                      $this: {
                                        lastProductFlag: {
                                          $eq: 'abbvie_suspect_product'
                                        }
                                      }
                                    },
                                    {
                                      $set: null
                                    }
                                  ]
                                }
                              ],
                              $else: { $set: null }
                            }
                          }
                        },
                        {
                          component: 'Section',
                          statePath: 'availability',
                          layout: {
                            margin: '0',
                            padding: '10px',
                            marginRight: '50%',
                            width: '50%'
                          },
                          styles: {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          },
                          referencedProperties: {
                            'layout.display': {
                              relative: true,
                              statePath: '../product_summary[0].product_flag',
                              $if: [
                                {
                                  $and: [
                                    { $ne: 'abbvie_suspect_product' },
                                    { $ne: null }
                                  ]
                                },
                                { $set: 'none' }
                              ],
                              $else: { $set: 'block' }
                            }
                          },
                          elements: [
                            {
                              component: 'Checkbox',
                              label: 'Not Available',
                              statePath: 'unavailable',
                              layout: {
                                padding: 5
                              },
                              referencedProperties: {
                                // Used to support AC defined in TRGY-1904. Value should clear on `null` flag
                                // but a new user value should be allowed with a `null` flag
                                lastProductFlag: {
                                  relative: true,
                                  statePath:
                                    '../../product_summary[0].product_flag'
                                },
                                value: {
                                  relative: true,
                                  statePath:
                                    '../../product_summary[0].product_flag',
                                  $if: [
                                    {
                                      $this: {
                                        $get: 'value'
                                      }
                                    },
                                    {
                                      $eq: 'abbvie_suspect_product'
                                    },
                                    {
                                      $this: { $get: '../visible' }
                                    },
                                    {
                                      $this: { $get: 'value' }
                                    }
                                  ],
                                  $elseIf: {
                                    $if: [
                                      {
                                        $this: {
                                          lastProductFlag: {
                                            $ne: 'abbvie_suspect_product'
                                          }
                                        }
                                      },
                                      {
                                        $this: { $get: '../visible' }
                                      },
                                      { $eq: null },
                                      {
                                        $this: {
                                          $get: 'value'
                                        }
                                      }
                                    ],
                                    $else: [
                                      {
                                        $this: {
                                          lastProductFlag: {
                                            $eq: 'abbvie_suspect_product'
                                          }
                                        }
                                      },
                                      {
                                        $eq: null
                                      },
                                      {
                                        $set: null
                                      }
                                    ]
                                  },
                                  $else: { $set: null }
                                },
                                disabled: {
                                  relative: true,
                                  statePath: '../',
                                  $or: [
                                    { $get: 'batch_lot_no' },
                                    { $get: 'availability.unknown' }
                                  ]
                                }
                              }
                            },
                            {
                              component: 'Checkbox',
                              label: 'Unknown',
                              statePath: 'unknown',
                              layout: {
                                padding: 5
                              },
                              referencedProperties: {
                                // Used to support AC defined in TRGY-1904. Value should clear on `null` flag
                                // but a new user value should be allowed with a `null` flag
                                lastProductFlag: {
                                  relative: true,
                                  statePath:
                                    '../../product_summary[0].product_flag'
                                },
                                value: {
                                  relative: true,
                                  statePath:
                                    '../../product_summary[0].product_flag',
                                  $if: [
                                    {
                                      $eq: 'abbvie_suspect_product'
                                    },
                                    {
                                      $this: { $get: '../visible' }
                                    },
                                    {
                                      $this: { $get: 'value' }
                                    }
                                  ],
                                  $elseIf: [
                                    {
                                      $if: [
                                        {
                                          $this: {
                                            lastProductFlag: {
                                              $ne: 'abbvie_suspect_product'
                                            }
                                          }
                                        },
                                        { $eq: null },
                                        {
                                          $this: {
                                            $get: 'value'
                                          }
                                        }
                                      ],
                                      $else: [
                                        {
                                          $this: {
                                            lastProductFlag: {
                                              $eq: 'abbvie_suspect_product'
                                            }
                                          }
                                        },
                                        {
                                          $eq: null
                                        },
                                        {
                                          $set: null
                                        }
                                      ]
                                    }
                                  ],
                                  $else: { $set: null }
                                },
                                disabled: {
                                  relative: true,
                                  statePath: '../',
                                  $or: [
                                    { $get: 'batch_lot_no' },
                                    { $get: 'availability.unavailable' }
                                  ]
                                }
                              }
                            }
                          ]
                        },
                        {
                          component: 'Checkbox',
                          statePath: 'related_to_product_complaint',
                          label: 'Related to Product Complaint',
                          layout: {
                            padding: 15,
                            width: '50%'
                          },
                          referencedProperties: {
                            'layout.display': {
                              statePath: 'subcases.productQuality.createdDate',
                              $if: [{ $ne: null }, { $set: 'block' }],
                              $else: { $set: 'none' }
                            }
                          }
                        }
                      ]
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Expiration Date',
                      statePath: 'expiration_date',
                      component: 'DateInput',
                      layout: {
                        marginRight: '58%',
                        display: 'block'
                      },
                      validations: {
                        date: {
                          constraint: 'partial',
                          errorMessage: 'Invalid partial date'
                        }
                      },
                      referencedProperties: {
                        // CSE doesn't seem to allow relative (ie. `../`) `statePath` values
                        // into another section (something that is relative itself). This enables
                        // this field to run an update on _every_ model update
                        _render: {
                          schemaPath: 'model',
                          relative: false
                        },
                        'layout.display': {
                          relative: true,
                          statePath: '../',
                          $if: [
                            {
                              $and: [
                                {
                                  'product_summary[0].suspect_product': {
                                    $ne: null
                                  }
                                },
                                { $set: 'block' }
                              ]
                            }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'daily_dose',
                      label: 'Daily Dose',
                      layout: {
                        width: '25%'
                      },
                      validations: {
                        pattern: {
                          constraint: '^\\d*\\.?\\d+$',
                          errorMessage: 'Please only enter numbers'
                        },
                        maxLength: {
                          constraint: 9,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'dose_text',
                          relative: true,
                          $if: [{ $ne: null }],
                          $else: { $set: false }
                        },
                        'validations.required.constraint': {
                          statePath: 'dose_units',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      $ref: 'UnitSelect',
                      statePath: 'dose_units',
                      pdfLabel: 'Dose Units',
                      layout: {
                        width: '25%'
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'dose_text',
                          relative: true,
                          $if: [{ $ne: null }],
                          $else: { $set: false }
                        },
                        'validations.required.constraint': {
                          relative: true,
                          statePath: 'daily_dose',
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      id: 'dose_text',
                      label: 'Dose Text',
                      component: 'TextInput',
                      statePath: 'dose_text',
                      layout: {
                        width: '50%',
                        display: 'block'
                      },
                      validations: {
                        maxLength: {
                          constraint: 98,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          $if: [
                            {
                              $compose: [
                                {
                                  $or: [
                                    { $get: 'daily_dose' },
                                    { $get: 'dose_units' },
                                    { $get: 'unit_dose' },
                                    { $get: 'unit_units' }
                                  ]
                                },
                                {
                                  $and: [{ $ne: null }]
                                }
                              ]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'unit_dose',
                      label: 'Unit Dose',
                      layout: {
                        width: '25%'
                      },
                      validations: {
                        pattern: {
                          constraint: '^\\d*\\.?\\d+$',
                          errorMessage: 'Please only enter numbers'
                        },
                        maxLength: {
                          constraint: 8,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'dose_text',
                          relative: true,
                          $if: [{ $ne: null }],
                          $else: { $set: false }
                        },
                        'validations.required.constraint': {
                          statePath: 'unit_units',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      $ref: 'UnitSelect',
                      statePath: 'unit_units',
                      pdfLabel: 'Unit Dose Units',
                      layout: {
                        width: '25%',
                        marginRight: '50%'
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'dose_text',
                          relative: true,
                          $if: [{ $ne: null }],
                          $else: { $set: false }
                        },
                        'validations.required.constraint': {
                          statePath: 'unit_dose',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      label: 'Form of Administration',
                      component: 'Select',
                      statePath: 'form_of_admin',
                      options: [],
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      maxLength: {
                        constraint: 50,
                        errorMessage:
                          'The maximum number of characters has been reached'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.form_of_admin'
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'form_strength',
                      label: 'Form Strength',
                      layout: {
                        width: '25%'
                      },
                      validations: {
                        pattern: {
                          constraint: '^\\d*\\.?\\d+$',
                          errorMessage: 'Please only enter numbers'
                        },
                        maxLength: {
                          constraint: 11,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        'validations.required.constraint': {
                          statePath: 'strength_units',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'strength_units',
                      label: 'Units',
                      pdfLabel: 'Form Strength Units',
                      options: [],
                      layout: {
                        width: '25%',
                        marginRight: '50%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.strength_units'
                        },
                        'validations.required.constraint': {
                          statePath: 'form_strength',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'route_of_admin',
                      label: 'Route of Administration',
                      options: [],
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.route_of_admin'
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'frequency',
                      label: 'Frequency',
                      layout: {
                        width: '15%'
                      },
                      validations: {
                        pattern: {
                          constraint: '^\\d+$',
                          errorMessage: 'Please only enter numbers'
                        },
                        maxLength: {
                          constraint: 3,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'frequency_text',
                          relative: true,
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        },
                        'validations.required.constraint': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { frequency_time: { $ne: null } },
                                { frequency_units: { $ne: null } }
                              ]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'frequency_time',
                      label: 'Frequency Time',
                      layout: {
                        width: '20%'
                      },
                      validations: {
                        pattern: {
                          constraint: '^\\d+$',
                          errorMessage: 'Please only enter numbers'
                        },
                        maxLength: {
                          constraint: 3,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'frequency_text',
                          relative: true,
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        },
                        'validations.required.constraint': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { frequency: { $ne: null } },
                                { frequency_units: { $ne: null } }
                              ]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'frequency_units',
                      label: 'Units',
                      pdfLabel: 'Frequency Units',
                      options: [],
                      layout: {
                        width: '15%'
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'frequency_text',
                          relative: true,
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        },
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.frequency_units'
                        },
                        'validations.required.constraint': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { frequency: { $ne: null } },
                                { frequency_time: { $ne: null } }
                              ]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'frequency_text',
                      label: 'Frequency Text',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        maxLength: {
                          constraint: 40,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { frequency: { $ne: null } },
                                { frequency_time: { $ne: null } },
                                { frequency_units: { $ne: null } }
                              ]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      label: 'Drug Start Date',
                      statePath: 'drug_start_date',
                      component: 'DateInput',
                      disabled: false,
                      validations: {
                        date: {
                          constraint: 'partial',
                          errorMessage: 'Invalid partial date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          statePath: 'drug_start_text',
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'drug_start_text',
                      label: 'Start Date Text',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        maxLength: {
                          constraint: 30,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          statePath: 'drug_start_date',
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      label: 'Drug Stop Date',
                      statePath: 'drug_stop_date',
                      component: 'DateInput',
                      validations: {
                        date: {
                          constraint: 'partial',
                          errorMessage: 'Invalid partial date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          statePath: 'drug_stop_text',
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'drug_stop_text',
                      label: 'Stop Date Text',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        maxLength: {
                          constraint: 30,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          statePath: 'drug_stop_date',
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'duration',
                      label: 'Duration',
                      layout: {
                        width: '25%',
                        display: 'block'
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'duration_text',
                          relative: true,
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        },
                        'validations.required.constraint': {
                          statePath: 'duration_units',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      },
                      validations: {
                        pattern: {
                          constraint: '^\\d+$',
                          errorMessage: 'Please only enter numbers'
                        },
                        maxLength: {
                          constraint: 5,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'duration_units',
                      label: 'Units',
                      pdfLabel: 'Duration Units',
                      options: [],
                      layout: {
                        width: '25%'
                      },
                      referencedProperties: {
                        disabled: {
                          statePath: 'duration_text',
                          relative: true,
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        },
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.ae-options.duration_units'
                        },
                        'validations.required.constraint': {
                          statePath: 'duration',
                          relative: true,

                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'duration_text',
                      label: 'Duration Text',
                      layout: {
                        width: '50%',
                        marginBottom: '20px'
                      },
                      validations: {
                        maxLength: {
                          constraint: 40,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { duration: { $ne: null } },
                                { duration_units: { $ne: null } }
                              ]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          $ref: 'AESaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Labs',
      component: 'Tab',
      path: 'labs',
      reconciliationPaths: ['subcases.adverseEvent.diagnostic_tests'],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'labs' } }, { $set: 'block' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'diagnostic_tests',
          component: 'Section',
          statePath: 'subcases.adverseEvent.diagnostic_tests',
          title: 'Diagnostic Tests',

          layout: {
            width: '100%',
            marginRight: '100%'
          },
          styles: {
            width: '100%'
          },
          elements: [
            {
              component: 'InputGroup',
              statePath: 'lab_results',
              label: '',
              addButtonLabel: 'Add another test',
              multiple: true,
              collapsible: true,
              length: 1,

              layout: {
                display: 'block'
              },

              referencedProperties: {
                label: {
                  relative: true,
                  $if: [{ test_name: { $ne: null } }, { $get: 'test_name' }],
                  $else: { $set: 'Test ${order}' }
                },
                'styles.display': {
                  schemaPath: './',
                  relative: true,
                  $if: [{ collapsed: { $eq: true } }, { $set: 'none' }],
                  $else: { $set: 'block' }
                },
                collapsed: {
                  schemaPath: './',
                  relative: true,
                  $if: [
                    { collapsed: { $eq: null } },
                    {
                      $var: ['labLength', { $get: '../length' }]
                    },
                    { order: { $lt: { $var: 'labLength' } } },
                    { $set: true }
                  ],
                  $else: {
                    $get: 'collapsed'
                  }
                }
              },

              elements: [
                {
                  component: 'TextInput',
                  label: 'Test Name',
                  statePath: 'test_name',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    required: {
                      constraint: false
                    },
                    maxLength: {
                      constraint: 30,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  },
                  referencedProperties: {
                    'validations.required.constraint': {
                      relative: true,
                      $if: [
                        {
                          $or: [
                            { specimen_type: { $ne: null } },
                            { test_date: { $ne: null } },
                            { test_results_numeric: { $ne: null } },
                            { units: { $ne: null } },
                            { high_test_range: { $ne: null } },
                            { low_test_range: { $ne: null } },
                            { test_results_text: { $ne: null } },
                            { $set: false }
                          ]
                        },
                        { $set: true }
                      ],
                      $else: { $set: false }
                    }
                  }
                },
                {
                  component: 'TextInput',
                  label: 'Specimen Type',
                  statePath: 'specimen_type',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 37,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  component: 'DateInput',
                  label: 'Test Date',
                  statePath: 'test_date',
                  layout: {
                    width: '50%',
                    marginRight: '50%'
                  },
                  validations: {
                    date: {
                      constraint: 'partial',
                      errorMessage: 'Invalid partial date'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    }
                  }
                },
                {
                  component: 'TextInput',
                  label: 'Test Results (numeric)',
                  statePath: 'test_results_numeric',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    pattern: {
                      constraint:
                        '^((>=)+|(\\d*\\.?\\d)+|(<=)+|\\++|\\s+|>+|<+|\\.|-+)*$',
                      errorMessage: 'Please only enter numbers'
                    },
                    maxLength: {
                      constraint: 50,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  },
                  referencedProperties: {
                    'validations.required.constraint': {
                      relative: true,
                      $or: [
                        { units: { $ne: null } },
                        {
                          $and: [
                            { $get: 'test_name' },
                            { test_results_text: { $eq: null } }
                          ]
                        }
                      ]
                    },
                    disabled: {
                      relative: true,
                      statePath: 'test_results_text',
                      $if: [{ $ne: null }, { $set: true }],
                      $else: { $set: false }
                    }
                  }
                },
                {
                  component: 'Select',
                  label: 'Units',
                  statePath: 'units',
                  pdfLabel: 'Test Results Units',
                  layout: {
                    width: '50%'
                  },
                  options: [],
                  referencedProperties: {
                    options: {
                      schemaPath: './',
                      $get: 'data.document-data.ae-options.test_units'
                    },
                    'validations.required.constraint': {
                      statePath: 'test_results_numeric',
                      relative: true,

                      $if: [{ $ne: null }, { $set: true }],
                      $else: { $set: false }
                    },
                    disabled: {
                      relative: true,
                      statePath: 'test_results_text',
                      $if: [{ $ne: null }, { $set: true }],
                      $else: { $set: false }
                    }
                  }
                },
                {
                  component: 'TextInput',
                  label: 'High Test Range',
                  statePath: 'high_test_range',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 10,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  component: 'TextInput',
                  label: 'Low Test Range',
                  statePath: 'low_test_range',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 10,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  component: 'TextArea',
                  label: 'Test Results (text)',
                  statePath: 'test_results_text',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 50,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  },
                  styles: {
                    minHeight: '150px'
                  },
                  referencedProperties: {
                    'validations.required.constraint': {
                      relative: true,
                      $and: [
                        { $get: 'test_name' },
                        { test_results_numeric: { $eq: null } }
                      ]
                    },
                    disabled: {
                      relative: true,
                      $or: [{ $get: 'test_results_numeric' }, { $get: 'units' }]
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          $ref: 'AESaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Patient',
      component: 'Tab',
      path: 'patient',
      reconciliationPaths: ['subcases.adverseEvent.patient'],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'patient' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'information',
          component: 'Section',
          statePath: 'subcases.adverseEvent.patient.information',
          title: 'Patient Information',
          elements: [
            {
              component: 'LabelValue',
              label: 'Initials:',
              value: '',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              referencedProperties: {
                value: {
                  statePath: 'patient.patient[0]',
                  $set: {
                    $or: [{ $get: 'initials' }, { $set: 'Not Specified' }]
                  }
                }
              }
            },
            {
              component: 'LabelValue',
              label: 'Gender:',
              value: '',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              referencedProperties: {
                value: {
                  statePath: 'patient.patient[0].gender',
                  $if: [{ $eq: 'm' }, { $set: 'Male' }],
                  $elseIf: [{ $eq: 'f' }, { $set: 'Female' }],
                  $else: [{ $set: 'Not Specified' }]
                }
              }
            },
            {
              component: 'LabelValue',
              label: 'Date of Birth:',
              value: '',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              valueTemplate: '<%= triggers.getAbbvieDate(value) %>',
              referencedProperties: {
                value: {
                  statePath: 'patient.patient[0]',
                  $set: { $get: 'dob' }
                },
                valueTemplate: {
                  $or: [
                    {
                      $and: [
                        {
                          'patient.patient[0].dob': {
                            $ne: null
                          }
                        },
                        {
                          $concat: [
                            '<%=triggers.getAbbvieDate(value) || value %>'
                          ]
                        }
                      ]
                    },
                    'Not Specified'
                  ]
                }
              }
            },
            {
              component: 'TextInput',
              statePath: 'age_at_event',
              label: 'Age at Time of Event',
              layout: {
                width: '25%'
              },
              validations: {
                pattern: {
                  constraint: '^\\d+$',
                  errorMessage: 'Please only enter numbers'
                },
                maxLength: {
                  constraint: 3,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              },
              referencedProperties: {
                'validations.required.constraint': {
                  statePath: 'age_at_event_unit',
                  relative: true,

                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                },
                'validations.required.constraint': {
                  relative: true,
                  $if: [
                    {
                      $or: [
                        {
                          age_at_event_unit: {
                            $ne: null
                          }
                        },
                        {
                          $and: [
                            {
                              '/patient.patient[0].privacy': {
                                $eq: true
                              }
                            },
                            {
                              '/patient.patient[0].dob': {
                                $ne: null
                              }
                            },
                            {
                              age_group: {
                                $eq: null
                              }
                            }
                          ]
                        }
                      ]
                    },
                    { $set: true }
                  ],
                  $else: { $set: false }
                },
                value: {
                  $if: [
                    {
                      $get: 'patient.patient[0].age'
                    },
                    {
                      $get: 'patient.patient[0].age'
                    }
                  ],
                  $else: { $set: { $this: { $get: 'value' } } }
                }
              },
              triggers: {
                onChange: 'syncPatientBiometrics'
              }
            },
            {
              component: 'Select',
              label: 'Units',
              pdfLabel: 'Age At Event Units',
              statePath: 'age_at_event_unit',
              layout: {
                width: '25%'
              },
              options: [],
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.age_units'
                },
                'validations.required.constraint': {
                  relative: true,
                  statePath: 'age_at_event',
                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                },
                value: {
                  $if: [
                    {
                      $get: 'patient.patient[0].ageUnits'
                    },
                    {
                      $get: 'patient.patient[0].ageUnits'
                    }
                  ],
                  $else: { $set: { $this: { $get: 'value' } } }
                }
              },
              triggers: {
                onChange: 'syncPatientBiometrics'
              }
            },
            {
              component: 'Select',
              statePath: 'age_group',
              label: 'Age Group',
              layout: {
                width: '50%'
              },
              options: [],
              referencedProperties: {
                'validations.required.constraint': {
                  relative: true,
                  $if: [
                    {
                      $and: [
                        {
                          '/patient.patient[0].privacy': {
                            $eq: true
                          }
                        },
                        {
                          '/patient.patient[0].dob': {
                            $ne: null
                          }
                        },
                        {
                          age_at_event: {
                            $eq: null
                          }
                        }
                      ]
                    },
                    { $set: true }
                  ],
                  $else: { $set: false }
                },
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.age_group'
                }
              }
            },
            {
              component: 'TextInput',
              statePath: 'height',
              label: 'Height',
              layout: {
                width: '10%'
              },
              validations: {
                pattern: {
                  constraint: '^\\d+$',
                  errorMessage: 'Please only enter numbers'
                },
                maxLength: {
                  constraint: 3,
                  errorMessage:
                    'The maximum number of characters has been reached'
                },
                heightLimit: {
                  errorMessage:
                    'Number is too high, please enter a smaller number',
                  queryConstraint: {
                    relative: true,
                    $if: [
                      {
                        $or: [
                          {
                            $and: [
                              { height_unit: { $eq: 'Centimeter' } },
                              { height: { $lt: 351 } }
                            ]
                          },
                          {
                            $and: [
                              { height_unit: { $eq: 'Inch' } },
                              { height: { $lt: 138 } }
                            ]
                          },
                          { height_unit: { $eq: 'Unknown' } },
                          { height_unit: { $eq: null } }
                        ]
                      },
                      {
                        $set: true
                      }
                    ]
                  }
                }
              },
              referencedProperties: {
                'validations.required.constraint': {
                  statePath: 'height_unit',
                  relative: true,

                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                }
              }
            },
            {
              component: 'Select',
              label: 'Units',
              pdfLabel: 'Height Units',
              statePath: 'height_unit',
              layout: {
                width: '15%',
                marginRight: '25%'
              },
              options: [],
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.height_unit'
                },
                'validations.required.constraint': {
                  statePath: 'height',
                  relative: true,

                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                }
              }
            },
            {
              component: 'TextInput',
              statePath: 'weight',
              label: 'Weight',
              layout: {
                width: '10%'
              },
              validations: {
                pattern: {
                  constraint: '^\\d+$',
                  errorMessage: 'Please only enter numbers'
                },
                maxLength: {
                  constraint: 4,
                  errorMessage:
                    'The maximum number of characters has been reached'
                },
                weightLimit: {
                  errorMessage:
                    'Number is too high, please enter a smaller number',
                  queryConstraint: {
                    relative: true,
                    $if: [
                      {
                        $or: [
                          {
                            $and: [
                              { weight_unit: { $eq: 'Kilogram' } },
                              { weight: { $lt: 2001 } }
                            ]
                          },
                          {
                            $and: [
                              { weight_unit: { $eq: 'Pound' } },
                              { weight: { $lt: 4410 } }
                            ]
                          },
                          { weight_unit: { $eq: 'Ounce' } },
                          { weight_unit: { $eq: 'Unknown' } },
                          { weight_unit: { $eq: null } }
                        ]
                      },
                      {
                        $set: true
                      }
                    ]
                  }
                }
              },
              referencedProperties: {
                'validations.required.constraint': {
                  statePath: 'weight_unit',
                  relative: true,

                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                },
                value: {
                  $if: [
                    {
                      $get: 'patient.patient[0].weight'
                    },
                    {
                      $get: 'patient.patient[0].weight'
                    }
                  ],
                  $else: { $set: { $this: { $get: 'value' } } }
                }
              },
              triggers: {
                onChange: 'syncPatientBiometrics'
              }
            },
            {
              component: 'Select',
              label: 'Units',
              pdfLabel: 'Weight Units',
              statePath: 'weight_unit',
              layout: {
                width: '15%'
              },
              options: [],
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.weight_unit'
                },
                'validations.required.constraint': {
                  statePath: 'weight',
                  relative: true,

                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                },
                value: {
                  $if: [
                    {
                      $get: 'patient.patient[0].weightUnits'
                    },
                    {
                      $get: 'patient.patient[0].weightUnits'
                    }
                  ],
                  $else: { $set: { $this: { $get: 'value' } } }
                }
              },
              triggers: {
                onChange: 'syncPatientBiometrics'
              }
            },
            {
              component: 'Select',
              statePath: 'pregnancy_related',
              label: 'Is patient pregnant?',
              options: [],
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              styles: {
                margin: '0'
              },
              validations: {
                pregnantGenderMismatch: {
                  errorMessage:
                    'Patient Pregnant cannot be YES if Patient is male',
                  queryConstraint: {
                    schemaPath: './',
                    $not: {
                      $and: [
                        {
                          'model.subcases.adverseEvent.patient.information.pregnancy_related': {
                            $eq: 'yes'
                          }
                        },
                        {
                          'model.patient.patient[0].gender': {
                            $eq: 'm'
                          }
                        }
                      ]
                    }
                  }
                }
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.generic_unknown'
                }
              },
              triggers: {
                onChange: 'onPregnancyChange'
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'TextInput',
              statePath: 'gestation_period',
              label: 'Gestation Period',
              layout: {
                width: '25%'
              },
              validations: {
                pattern: {
                  constraint: '^\\d+$',
                  errorMessage: 'Please only enter numbers'
                },
                maxLength: {
                  constraint: 3,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              },
              referencedProperties: {
                'layout.display': {
                  statePath:
                    'subcases.adverseEvent.patient.information.pregnancy_related',
                  $if: [{ $eq: 'yes' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                },
                'validations.required.constraint': {
                  statePath: 'gestation_units',
                  relative: true,

                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'Select',
              statePath: 'gestation_units',
              label: 'Units',
              pdfLabel: 'Gestation Units',
              layout: {
                width: '25%',
                marginRight: '50%'
              },
              options: [],
              referencedProperties: {
                'layout.display': {
                  statePath:
                    'subcases.adverseEvent.patient.information.pregnancy_related',
                  $if: [{ $eq: 'yes' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                },
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.gestation_units'
                },
                'validations.required.constraint': {
                  statePath: 'gestation_period',
                  relative: true,

                  $if: [{ $ne: null }, { $set: true }],
                  $else: { $set: false }
                }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              label: 'Last Menstrual Period (LMP)',
              component: 'DateInput',
              statePath: 'last_menstrual_period',

              layout: {
                width: '50%'
              },
              validations: {
                date: {
                  constraint: 'partial',
                  errorMessage: 'Invalid partial date'
                },
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                },
                required: {
                  constraint: false,
                  errorMessage: ''
                }
              },
              referencedProperties: {
                'layout.display': {
                  statePath:
                    'subcases.adverseEvent.patient.information.pregnancy_related',
                  $if: [{ $eq: 'yes' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              label: 'Expected Delivery Date (EDD)',
              component: 'DateInput',
              statePath: 'expected_delivery_date',

              layout: {
                width: '50%'
              },
              validations: {
                date: {
                  constraint: 'partial',
                  errorMessage: 'Invalid partial date'
                }
              },
              referencedProperties: {
                'layout.display': {
                  statePath:
                    'subcases.adverseEvent.patient.information.pregnancy_related',
                  $if: [{ $eq: 'yes' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            }
          ]
        },
        {
          id: 'history',
          component: 'Section',
          statePath: 'subcases.adverseEvent.patient.history',
          title: 'Patient History',
          elements: [
            {
              component: 'Select',
              label: 'Alcohol Use',
              statePath: 'alcohol_user',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              options: [],
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.generic_yes_no'
                }
              }
            },
            {
              $ref: 'PatientAlcoholHistory',
              statePath: 'alcohol_history',
              referencedProperties: {
                'layout.display': {
                  statePath:
                    'subcases.adverseEvent.patient.history.alcohol_user',
                  $if: [{ $eq: 'yes' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              component: 'Select',
              label: 'Tobacco Use',
              statePath: 'tobacco_user',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              options: [],
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.generic_yes_no'
                }
              }
            },
            {
              $ref: 'PatientTobaccoHistory',
              statePath: 'tobacco_history',
              referencedProperties: {
                'layout.display': {
                  statePath:
                    'subcases.adverseEvent.patient.history.tobacco_user',
                  $if: [{ $eq: 'yes' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              component: 'Select',
              label: 'Does the patient have any allergies?',
              statePath: 'allergies',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              options: [],
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.generic_yes_no'
                }
              }
            },
            {
              $ref: 'PatientAllergyHistory',
              statePath: 'allergies_section'
            }
          ]
        },
        {
          id: 'MedicalHistory',
          component: 'Section',
          title: 'Medical History',
          statePath: 'subcases.adverseEvent.patient.medical_history',
          elements: [
            {
              component: 'InputGroup',
              statePath: 'medical_histories',
              collapsible: true,
              multiple: true,
              length: 1,
              label: 'another medical history', // overwritten by ref props
              layout: {
                display: 'block'
              },
              referencedProperties: {
                'styles.display': {
                  schemaPath: './',
                  relative: true,
                  $if: [{ collapsed: { $eq: true } }, { $set: 'none' }],
                  $else: { $set: 'block' }
                },
                label: {
                  $if: [
                    { medical_history_term: { $ne: null } },
                    { $set: { $get: 'medical_history_term' } }
                  ],
                  $else: { $set: 'another medical history' },
                  relative: true
                },
                collapsed: {
                  schemaPath: './',
                  relative: true,
                  $if: [
                    { collapsed: { $eq: null } },
                    {
                      $var: ['historyLength', { $get: '../length' }]
                    },
                    { order: { $lt: { $var: 'historyLength' } } },
                    { $set: true }
                  ],
                  $else: {
                    $get: 'collapsed'
                  }
                }
              },
              elements: [
                {
                  component: 'TextInput',
                  label: 'Medical History Term',
                  statePath: 'medical_history_term',
                  layout: {
                    width: '50%',
                    marginRight: '50%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 87,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  component: 'DateInput',
                  statePath: 'medical_history_start_date',
                  label: 'Start Date',
                  layout: {},
                  validations: {
                    date: {
                      constraint: 'partial',
                      errorMessage: 'Invalid partial date'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    }
                  }
                },
                {
                  component: 'Checkbox',
                  statePath: 'medical_history_continuing',
                  label: 'Continuing?',
                  layout: {
                    alignSelf: 'center',
                    display: 'flex',
                    marginTop: 5,
                    padding: 15
                  }
                },
                {
                  component: 'DateInput',
                  statePath: 'medical_history_stop_date',
                  label: 'End Date',
                  layout: {},
                  validations: {
                    date: {
                      constraint: 'partial',
                      errorMessage: 'Invalid partial date'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    }
                  }
                }
              ]
            },
            {
              id: 'medical history description',
              statePath: 'medical_history_group',
              component: 'InputGroup',
              label: 'medical history description',
              collapsible: false,
              multiple: false,
              length: 0,

              layout: {
                order: 1,
                width: '100%'
              },

              elements: [
                {
                  component: 'TextArea',
                  label: 'Medical History Description',
                  statePath: 'medical_history_description',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 10000,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  },
                  styles: {
                    height: '176px',
                    width: '100%'
                  }
                }
              ]
            }
          ]
        },
        {
          $ref: 'AESaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Parent Pregnancy',
      component: 'Tab',
      path: 'pregnancy',
      reconciliationPaths: ['subcases.adverseEvent.pregnancy'],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'pregnancy' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'products',
          component: 'Section',
          statePath: 'subcases.adverseEvent.pregnancy.parent',
          title: 'Products',
          elements: [
            {
              id: 'ParentProductList',
              label: 'another product',
              component: 'InputGroup',
              statePath: 'products',
              multiple: true,
              collapsible: false,
              length: 1,
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              elements: [
                {
                  component: 'TextArea',
                  label: 'Parent Product',
                  statePath: 'product',
                  layout: {
                    width: '100%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 84,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          id: 'details',
          component: 'Section',
          statePath: 'subcases.adverseEvent.pregnancy.details',
          title: 'Parent/Pregnancy Details',
          elements: [
            {
              label: 'Expected Delivery Date (EDD)',
              component: 'DateInput',
              statePath: 'expectedDeliveryDate',

              layout: {
                width: '50%'
              },
              validations: {
                date: {
                  constraint: 'partial',
                  errorMessage: 'Invalid partial date'
                }
              }
            },
            {
              label: 'End of Pregnancy (EOP)',
              component: 'DateInput',
              statePath: 'endOfPregnancy',

              layout: {
                width: '50%'
              },
              validations: {
                date: {
                  constraint: 'partial',
                  errorMessage: 'Invalid partial date'
                },
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              label: 'Last Menstrual Period (LMP)',
              component: 'DateInput',
              statePath: 'lastMenstrualPeriod',

              layout: {
                width: '50%',
                marginRight: '50%'
              },
              validations: {
                date: {
                  constraint: 'partial',
                  errorMessage: 'Invalid partial date'
                },
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              $ref: 'GenderSelect',
              statePath: 'gender',

              layout: {
                width: '50%',
                display: 'inline-block'
              }
            },
            {
              component: 'TextInput',
              statePath: 'initials',
              label: 'Parent Initials',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 10,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              label: 'Date of Birth',
              component: 'DateInput',
              statePath: 'dateOfBirth',

              layout: {
                width: '50%'
              },
              validations: {
                date: {
                  constraint: 'partial',
                  errorMessage: 'Invalid partial date'
                },
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              component: 'TextInput',
              statePath: 'age',
              label: 'Age',
              layout: {
                width: '25%'
              },
              validations: {
                pattern: {
                  constraint: '^\\d+$',
                  errorMessage: 'Please only enter numbers'
                },
                maxLength: {
                  constraint: 2,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              id: 'AgeUnitSelect',
              label: 'Units',
              pdfLabel: 'Age Units',
              name: 'AgeUnits',
              component: 'Select',
              statePath: 'ageUnits',
              layout: {
                width: '25%'
              },
              options: [],
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.age_units'
                }
              }
            },
            {
              component: 'TextArea',
              statePath: 'parentMedHistory',
              label: 'Parent Medical History',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 100,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              },
              styles: {
                height: '90px',
                width: '100%'
              }
            },
            {
              component: 'TextInput',
              statePath: 'methodOfDelivery',
              label: 'Method of Delivery',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 100,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              id: 'procedures',
              label: 'another lab/procedure',
              component: 'InputGroup',
              statePath: 'procedures',
              multiple: true,
              collapsible: false,
              length: 1,
              layout: {
                width: '100%',
                '> section': {
                  marginRight: '50%',
                  width: '50%'
                }
              },
              elements: [
                {
                  component: 'TextArea',
                  label: 'Parent Lab/Diagnostic Procedures',
                  statePath: 'procedure',
                  layout: {
                    width: '100%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 500,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          $ref: 'AESaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Tracking',
      component: 'Tab',
      path: 'tracking',
      reconciliationPaths: [
        'tracking',
        'subcases.adverseEvent.tracking',
        'subcases.adverseEvent.submission',
        'reference_numbers'
      ],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'tracking' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          $ref: 'AEAdditionalInfoSection',
          title: 'AE Additional Information',
          statePath: 'subcases.adverseEvent.tracking'
        },
        {
          $ref: 'AESubmissionSection',
          statePath: 'subcases.adverseEvent.submission'
        },
        {
          id: 'ReferenceNumbers',
          component: 'Section',
          statePath: 'reference_numbers',
          title: 'Reference Numbers',
          elements: [
            { $ref: 'ReferenceNumber' },
            {
              $ref: 'LinkedCases',
              statePath: '/linkedCases'
            },
            {
              $ref: 'ClonedFrom'
            },
            { $ref: 'AERNumbers' }
          ]
        },
        {
          $ref: 'TrackingInformationSection',
          title: 'Master Case Information',
          statePath: 'tracking'
        },
        {
          $ref: 'InternalCommentsSection',
          statePath: 'subcases.adverseEvent.comments'
        },
        {
          $ref: 'AESaveSubmitActionSection'
        }
      ]
    },
    { $ref: 'SharedInfo' }
  ]
};
