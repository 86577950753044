import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { openEmailClient } from 'Common/utils';
import { SimpleButton, Select, TextInput,Checkbox, TextArea } from 'Common/components/Form';
import { EMAIL_REGEX } from 'Common/components/Form/constants';
import { modalStyles } from 'Common/components/withModal';
import Notifier from 'Common/components/Notifier';
import sendAckEmail from 'api/rest/sendAckEmail';
import {  get, set } from 'lodash';

import stylesGenerator from './styles';
import withStyles from 'Common/components/Form/withStyles';

class GenerateCustomerServiceModal extends PureComponent {
  static propTypes = {
    // computedStyles: PropTypes.objectOf(PropTypes.object).isRequired,
    computedStyles: PropTypes.shape({
      fileListItem: PropTypes.object.isRequired,
    }).isRequired,
    recipient: PropTypes.string.isRequired,
    masterCaseId: PropTypes.string.isRequired,
    onRecipientChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    ack: PropTypes.bool,
  };

  static defaultProps = {
    onConfirm: () => {},
    ack: false,
  };

  state = {
    attachments:[],
    otherEmail: '',
    reasonCode:'',
    selectedEmails: null,
    shipToAccount:'',
    emailBodyCustomerService:'',
    sendCustomerServiceEmail:false,
    showTextArea: false
    
  };

  // emailOptions = [
  //   { label: 'GMEC-Offline@abbvie.com', value: 'GMEC-Offline@abbvie.com' },
  //   { label: 'AAProductReplacements@allergan.com', value: 'AAProductReplacements@allergan.com' },
  //   { label: 'care@skinmedica.com', value: 'care@skinmedica.com' },
  //   { label: 'Support@brilliantconnections.com', value: 'Support@brilliantconnections.com' },
  //   { label: 'GPONoChargeOrders@abbvie.com', value: 'GPONoChargeOrders@abbvie.com' },
  //   { label: 'Eyecareorders@allergan.com', value: 'Eyecareorders@allergan.com' },
  //   { label: 'Other', value: 'Other' },
  // ];

  handleSelectChange = (selectedOption) => {
    
    if (selectedOption === 'Other') {
      this.setState({
        showTextArea: true,
        selectedEmails: selectedOption,
      });
    } else {
      this.setState({
        selectedEmails: selectedOption,
        showTextArea: false,
        otherEmail: '',
      });
    }
  };
  handleTextChange = (value) => {
    
    this.setState({ otherEmail: value.trim() });
  };
  handleCheckChange = (attachment) => {
    
    this.setState((prevState) => {
      const { attachments } = prevState;

      if (!attachments.includes(attachment)) {
        return { attachments: [...attachments, attachment] }; // Add to attachments
      } else {
        return { attachments: attachments.filter((a) => a !== attachment) }; // Remove from attachments
      }
    });
  };


  handleGenerateCustomerServiceConfirm = (ack) => {
    const { masterCaseId, onConfirm, trilogyCase, actions, match, triggers} = this.props;
    let page =''
    let id ='';
    if(match && match.params && match.params.page) {
      page = match.params.page
    }
    if( page === "ae"){
      id = get(trilogyCase,"subcases.adverseEvent.id")
    } else if (page === "pq"){
      id = get(trilogyCase,"subcases.productQuality.id")
    } else {
      id = masterCaseId
    }
    const recipient = this.state.showTextArea
      ? this.state.otherEmail
      : (this.state.selectedEmails ? this.state.selectedEmails : '');

    console.log("Recipient before generating email:", recipient); // Debug log
    console.log("props", this.props)
    const subject = `[ENCRYPT] ${masterCaseId}`;


       const list = get(trilogyCase,"customerServiceDetails") || []

       let item ={ recipient:recipient,
        attachments:this.state.attachments,
        caseID:id,
      reasonCode: this.state.reasonCode,
        shipToAccount:this.state.shipToAccount,
        emailBodyCustomerService:this.state.emailBodyCustomerService,
        sendCustomerServiceEmail:this.state.sendCustomerServiceEmail
       }
       list.push(item)

       set(trilogyCase,'customerServiceDetails',list)

    actions.emitInputBatchUpdate(trilogyCase);
    actions.emitSaveCase(trilogyCase, match.params);

    //calling the email application
    // openEmailClient(recipient, subject);
    onConfirm();
    
  };

  sanitizeInput = () => {
    const recipient = this.state.showTextArea
      ? this.state.otherEmail
      : (this.state.selectedEmails ? this.state.selectedEmails : '');

    return !EMAIL_REGEX.test(recipient) || recipient.trim() === '';
  };
renderFileList =()=>{
    const {computedStyles, trilogyCase} = this.props
    const {documents} = trilogyCase
   

  return(
    

      <ul className={computedStyles.fileList}>
{documents && documents.attachments && documents.attachments.length > 0 ? (
          documents.attachments.map((attachment, index) => (
            <li key={index} className={computedStyles.fileListItem}>
              <div className={`with-padding ${computedStyles.listItemRow}`}>
                <div className={computedStyles.listItemColumn}>
                    <i className={computedStyles.filaName} >
                    <span>{attachment.ext}</span>
                  </i>
                </div>
                <div className={computedStyles.listItemColumn}>
                  {attachment.name}
                </div>
                <div  className={computedStyles.editItemContainer}>
                <Checkbox id={`attachment-${index}`} className={computedStyles.listChekbox}  style ={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 'auto'
                    }}
                    value={this.state.attachments.includes(attachment)}
                onChange = {()=>this.handleCheckChange(attachment)}
                  />
                </div>
                          
              </div>
            </li>
          ))
        ) : (
    <li className={computedStyles.fileListItem}>No attachments found!!</li>
        )}
      </ul>

  )
   
    
    
}
  render() {
    const { onCancel, ack, computedStyles, tacticalData} = this.props;
    const emailOptions = get(
      tacticalData,
      'document-data.mastercase-options.generate_customer_service_email',
      []
    );
    const { shipToAccount, selectedEmails, showTextArea, otherEmail, reasonCode, emailBodyCustomerService } = this.state;
    return (
    
      <div className={`${modalStyles.base} ${modalStyles.modal}`}>
        <span className={modalStyles.title}>
          Generate Customer Service Email
        </span>
        <Select
          options={emailOptions}
          value={selectedEmails}
          onChange={this.handleSelectChange}
          placeholder="Select an email"
        />
        {showTextArea && (
          <TextInput
            label="Please specify other email addresses"
            value={otherEmail}
            required
            onChange={ this.handleTextChange}
            locale="US"
          />
        )}
        <div style ={{ padding: '6px 0 0 0'}}>
          <TextInput
            label="Customer Service Universal Ship to Account #:"
            value={shipToAccount}
            required
            onChange={e => this.setState({ shipToAccount: e })}
            locale="US"
          />
        </div>
        <div style ={{ padding: '8px 0 0 0'}}>
          <TextInput
            label="Reason Code:"
            value={reasonCode}
            required
        onChange={(e)=>this.setState({reasonCode:e})}
            locale="US"
          />
        </div>
        <div style ={{ padding: '8px 0 0 0'}}>
          <TextArea
          styles={{
            height: '100px'
          }}
            label="Email Body:"
            value={emailBodyCustomerService}
            required
        onChange={(e)=>this.setState({emailBodyCustomerService:e})}
            locale="US"
          />
        </div>

        {this.renderFileList()}
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={onCancel}>Cancel</SimpleButton>
          <SimpleButton
            onClick={() => this.handleGenerateCustomerServiceConfirm(ack)}
            primary
            disabled={this.sanitizeInput()}
          >
            Generate Email
          </SimpleButton>
        </div>
      </div>
      // </div>
    );
  }
}

export default withStyles(stylesGenerator)(GenerateCustomerServiceModal);

