import { get, isNil, isString, find, capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import {
  getOrElse,
  getUserName,
  getSubcaseProductValue,
  formatProductTypes
} from 'Common/utils';
import { abbvieDateFormat } from 'Common/components/Form';
import {
  STATUSES_MAP,
  NOT_SPECIFIED,
  CC_SUB_CASE_TYPES_MAP,
  N_A
} from 'Common/constants';
import {
  AE_STATE_PATHS,
  MASTER_STATE_PATHS,
  PQ_STATE_PATHS,
  SERIOUSNESS_OPTIONS,
  MI_STATE_PATHS,
  REPLACEMENT_REQUIRED_OPTIONS,
  CUSTOMER_RESPONSE_REQUIRED_OPTIONS,
  SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS
} from 'Queue/constants';
import {
  SERIOUSNESS as SERIOUSNESS_TASKS,
  REACTIONS as REACTIONS_TASKS,
  SERIOUSNESS_OPTIONS as SERIOUSNESS_OPTIONS_TASKS,
  TYPE_MAP,
  NEW_TASK_ID
} from 'Tasks/constants';

const hasStringData = input =>
  !isNil(input) && isString(input) && input.length > 0;

const processNonMomentDate = input =>
  !isNil(input) && input.length > 0 ? input : NOT_SPECIFIED;

const getAEColumnValue = (row = {}, label = '', tacticalData) => {
  const users = get(tacticalData, 'document-data.user-list', []);
  const subcaseId = getOrElse(row, AE_STATE_PATHS.ID);
  const country = getOrElse(
    row,
    AE_STATE_PATHS.COUNTRY_OF_REPORTER_LABEL,
    NOT_SPECIFIED
  );

  const maybeAssignee = getUserName(
    users,
    getOrElse(row, AE_STATE_PATHS.ASSIGNEE)
  );

  const productName = getSubcaseProductValue(
    row,
    tacticalData,
    CC_SUB_CASE_TYPES_MAP.ae,
    NOT_SPECIFIED
  );

  const dateOutputAE = abbvieDateFormat(
    getOrElse(row, AE_STATE_PATHS.ABBVIE_AWARENESS_DATE)
  );

  const abbvieAwarenessAE = hasStringData(dateOutputAE)
    ? dateOutputAE
    : NOT_SPECIFIED;

  const dueDateOutput = abbvieDateFormat(
    getOrElse(row, AE_STATE_PATHS.SUBMISSION_DUE_DATE)
  );

  const dueDate = hasStringData(dueDateOutput) ? dueDateOutput : NOT_SPECIFIED;

  let reactions = getOrElse(row, AE_STATE_PATHS.REACTIONS);
  reactions = reactions || NOT_SPECIFIED;

  let methodOfReceipt = getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT);
  methodOfReceipt = hasStringData(methodOfReceipt)
    ? methodOfReceipt
    : NOT_SPECIFIED;

  let affiliateCountryLabel = getOrElse(
    row,
    MASTER_STATE_PATHS.AFFILIATE_COUNTRY_LABEL
  );
  affiliateCountryLabel = hasStringData(affiliateCountryLabel)
    ? affiliateCountryLabel
    : NOT_SPECIFIED;

  switch (label) {
    case 'Case Information':
      return subcaseId;
    case 'Country':
      return country;
    case 'Product':
      return productName;
    case 'Latest Received Date':
      return processNonMomentDate(
        abbvieDateFormat(getOrElse(row, AE_STATE_PATHS.LAST_RECEIVED_DATE))
      );
    case 'Due Date':
      return dueDate;
    case 'AE Owner':
      return maybeAssignee || 'Unassigned';
    case 'Status':
      return STATUSES_MAP[get(row, AE_STATE_PATHS.STATUS, 'NEW')];
    case 'Submitted?':
      return getOrElse(row, AE_STATE_PATHS.SUBMITTED) ? 'Yes' : 'No';
    case 'Reactions':
      return reactions;
    case 'Abbvie Awareness Date':
      return abbvieAwarenessAE;
    case 'Method of Receipt':
      return methodOfReceipt;
    case 'Affiliate Location':
      return affiliateCountryLabel;
    default:
      return NOT_SPECIFIED;
  }
};

const getPQColumnValue = (row, label, tacticalData) => {
  const users = get(tacticalData, 'document-data.user-list', []);
  const subcaseId = getOrElse(row, PQ_STATE_PATHS.ID);
  const maybeAssignee = getUserName(
    users,
    getOrElse(row, PQ_STATE_PATHS.ASSIGNEE)
  );

  const masterCaseProductOptions = get(
    tacticalData,
    'document-data.mastercase-product-options'
  );

  const masterCaseProductMatch = find(
    masterCaseProductOptions,
    ({ value }) => value === getOrElse(row, PQ_STATE_PATHS.PRODUCT)
  );

  const productName = get(masterCaseProductMatch, 'label', NOT_SPECIFIED);

  let affililateSampleReceivedDate = abbvieDateFormat(
    getOrElse(row, PQ_STATE_PATHS.AFFILIATE_RETURN_SAMPLE_RECEIVED_DATE)
  );

  affililateSampleReceivedDate = hasStringData(affililateSampleReceivedDate)
    ? affililateSampleReceivedDate
    : NOT_SPECIFIED;

  let batchLotNumber = getOrElse(row, PQ_STATE_PATHS.BATCH_LOT_NUMBER);
  batchLotNumber = batchLotNumber || NOT_SPECIFIED;

  const customerResponceMatch = find(
    CUSTOMER_RESPONSE_REQUIRED_OPTIONS.options,
    ({ value }) =>
      value === getOrElse(row, PQ_STATE_PATHS.CUSTOMER_RESPONSE_REQUIRED)
  );

  const customerResponceRequired = get(
    customerResponceMatch,
    'label',
    NOT_SPECIFIED
  );

  const initialReporterContact = find(
    getOrElse(row, 'contacts.contact', []),
    c => getOrElse(c, 'pq.initial_reporter', false) === true
  );

  const patientCountry = getOrElse(
    row,
    'patient.patient[0].contact_info[0].country',
    null
  );

  const initialReporterCountry = getOrElse(
    initialReporterContact,
    'country',
    null
  );

  const countryReporter =
    initialReporterContact && initialReporterContact.type === 'patient'
      ? patientCountry
      : initialReporterCountry;

  const countryOptions = get(
    tacticalData,
    'document-data.country-options',
    []
  ).find(c => c.value === countryReporter);
  const countryOfReporterLabel = get(countryOptions, 'label', NOT_SPECIFIED);

  let sampleAvailableforReturn = getOrElse(
    row,
    PQ_STATE_PATHS.SAMPLE_AVAILABLE_FORRETURN
  );
  sampleAvailableforReturn =
    capitalize(sampleAvailableforReturn) || NOT_SPECIFIED;

  let replacementReferenceNumber = getOrElse(
    row,
    PQ_STATE_PATHS.REPLACEMENT_REFERENCE_NUMBER
  );
  replacementReferenceNumber = replacementReferenceNumber || NOT_SPECIFIED;

  let listNumber = getOrElse(row, PQ_STATE_PATHS.LIST_NUMBER);
  listNumber = listNumber || NOT_SPECIFIED;

  let solTRAQsRefNumber = getOrElse(
    row,
    PQ_STATE_PATHS.SOLTRAQS_REFERENCE_NUMBER
  );
  solTRAQsRefNumber = solTRAQsRefNumber || NOT_SPECIFIED;

  // let replacementRequired = getOrElse(row, PQ_STATE_PATHS.REPLACEMENT_REQUIRED);
  // replacementRequired = replacementRequired || NOT_SPECIFIED;

  const replacementRequiredOptions = REPLACEMENT_REQUIRED_OPTIONS.options;
  const replacementRequiredMatch = find(
    replacementRequiredOptions,
    ({ value }) => value === getOrElse(row, PQ_STATE_PATHS.REPLACEMENT_REQUIRED)
  );
  const replacementRequired = get(
    replacementRequiredMatch,
    'label',
    NOT_SPECIFIED
  );

  const sampleRequiredforInvestigationOptions =
    SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS.options;
  const sampleRequiredforInvestigationMatch = find(
    sampleRequiredforInvestigationOptions,
    ({ value }) =>
      value === getOrElse(row, PQ_STATE_PATHS.SAMPLE_REQUIRED_FOR_INVESTIGATION)
  );
  const sampleRequiredforInvestigation = get(
    sampleRequiredforInvestigationMatch,
    'label',
    NOT_SPECIFIED
  );

  let productMarkedName = getOrElse(row, PQ_STATE_PATHS.PRODUCT_MARKET_NAME);
  productMarkedName = productMarkedName || NOT_SPECIFIED;

  let affiliateSampleShipDate = abbvieDateFormat(
    getOrElse(row, PQ_STATE_PATHS.AFFILIATE_SAMPLE_SHIP_DATE)
  );
  affiliateSampleShipDate = hasStringData(affiliateSampleShipDate)
    ? affiliateSampleShipDate
    : NOT_SPECIFIED;

  let hCPRegulatoryAgencyCommunication = getOrElse(
    row,
    PQ_STATE_PATHS.HCP_REGULATORY_AGENCY_COMMUNICATION
  );
  hCPRegulatoryAgencyCommunication =
    capitalize(hCPRegulatoryAgencyCommunication) || NOT_SPECIFIED;

  let sampleTrackingtoCustomers = getOrElse(
    row,
    PQ_STATE_PATHS.SAMPLE_TRACKING_TO_CUSTOMERS
  );
  sampleTrackingtoCustomers = sampleTrackingtoCustomers || NOT_SPECIFIED;

  let submissionDatetoSolTRAQs = getOrElse(
    row,
    PQ_STATE_PATHS.SUBMISSION_DATE_TO_SOLTRAQS
  );
  submissionDatetoSolTRAQs = hasStringData(submissionDatetoSolTRAQs)
    ? submissionDatetoSolTRAQs
    : NOT_SPECIFIED;

  let methodOfReceipt = getOrElse(row, PQ_STATE_PATHS.METHOD_OF_RECEIPT);
  methodOfReceipt = hasStringData(methodOfReceipt)
    ? methodOfReceipt
    : NOT_SPECIFIED;

  let affiliateCountryLabel = getOrElse(
    row,
    MASTER_STATE_PATHS.AFFILIATE_COUNTRY_LABEL
  );
  affiliateCountryLabel = hasStringData(affiliateCountryLabel)
    ? affiliateCountryLabel
    : NOT_SPECIFIED;

  const highImpact = capitalize(get(row, PQ_STATE_PATHS.HIGH_IMPACT, ''));
  const reconciliation = (row.subcases.productQuality.reconciliation ==null) ? NOT_SPECIFIED : abbvieDateFormat(
    row.subcases.productQuality.reconciliation.slice(-1)[0].wentIntoReconModeDate
  );
  switch (label) {
    case 'Case Information':
      return subcaseId;
    case 'High Impact':
      return highImpact || NOT_SPECIFIED;
    case 'Country of Reporter':
      return countryOfReporterLabel;
    case 'Product':
      return productName;
    case 'Abbvie Awareness (PQ)':
      return processNonMomentDate(
        abbvieDateFormat(getOrElse(row, PQ_STATE_PATHS.AWARENESS_DATE))
      );
    case 'PQ Owner':
      return maybeAssignee || 'Unassigned';
    case 'Status':
      return STATUSES_MAP[get(row, PQ_STATE_PATHS.STATUS, 'NEW')];
    case 'Affiliate Return Sample Received Date':
      return affililateSampleReceivedDate;
    case 'Batch/Lot Number':
      return batchLotNumber;
    case 'Customer Response Required?':
      return customerResponceRequired;
    case 'Sample Available for Return':
      return sampleAvailableforReturn.toString();
    case 'Replacement Reference Number':
      return replacementReferenceNumber;
    case 'List Number':
      return listNumber;
    case 'SolTRAQs Reference Number':
      return solTRAQsRefNumber;
    case 'Replacement Required':
      return replacementRequired;
    case 'Sample Required for Investigation':
      return sampleRequiredforInvestigation;
    case 'Product (Marketed Name)':
      return productMarkedName;
    case 'Affiliate Sample Ship Date':
      return affiliateSampleShipDate;
    case 'HCP Regulatory Agency Communication':
      return hCPRegulatoryAgencyCommunication.toString();
    case 'Sample Tracking to Customers':
      return sampleTrackingtoCustomers;
    case 'Submission Date to SolTRAQs':
      return submissionDatetoSolTRAQs;
    case 'Method of Receipt':
      return methodOfReceipt;
    case 'Affiliate Location':
      return affiliateCountryLabel;
    case 'PQ went into Reconciliation':
      return reconciliation;  
    default:
      return NOT_SPECIFIED;
  }
};

const getYourCaseColumnValue = (row, label, tacticalData) => {
  const dueDate = abbvieDateFormat(getOrElse(row, MASTER_STATE_PATHS.DUEDATE));
  const datedueDate = hasStringData(dueDate) ? dueDate : NOT_SPECIFIED;

  const affiliateAwarenessDate = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.SUMMARY_AFFILIATE_AWARENESS_DATE)
  );
  const dateAffiliateAwarenessDate = hasStringData(affiliateAwarenessDate)
    ? affiliateAwarenessDate
    : NOT_SPECIFIED;

  const dateOutputAE = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_AE)
  );
  const abbvieAwarenessAE = hasStringData(dateOutputAE)
    ? dateOutputAE
    : NOT_SPECIFIED;

  const dateOutputPQ = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_PQ)
  );
  const abbvieAwarenessPQ = hasStringData(dateOutputPQ)
    ? dateOutputPQ
    : NOT_SPECIFIED;

  const trilogyLoadDate = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.TRILOGYLOADDATE)
  );
  const datetrilogyLoadDate = hasStringData(trilogyLoadDate)
    ? trilogyLoadDate
    : NOT_SPECIFIED;

  const seriousnessOptions = SERIOUSNESS_OPTIONS.options;
  const seriousnessOptionsMatch = find(
    seriousnessOptions,
    ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.SERIOUSNESS)
  );
  const seriousness = get(seriousnessOptionsMatch, 'label', NOT_SPECIFIED);

  const replacementRequiredOptions = REPLACEMENT_REQUIRED_OPTIONS.options;
  const replacementRequiredMatch = find(
    replacementRequiredOptions,
    ({ value }) =>
      value === getOrElse(row, MASTER_STATE_PATHS.REPLACEMENT_REQUIRED)
  );
  const replacementRequired = get(
    replacementRequiredMatch,
    'label',
    NOT_SPECIFIED
  );

  const methodOfReceiptOptions = get(
    tacticalData,
    'document-data.mastercase-options.method_of_receipt',
    []
  );
  const methodOfReceiptMatch = find(
    methodOfReceiptOptions,
    ({ value }) =>
      value === getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT)
  );
  const methodOfReceipt = get(methodOfReceiptMatch, 'label', NOT_SPECIFIED);

  const highImpact = capitalize(
    get(row, MASTER_STATE_PATHS.HIGH_IMPACT, NOT_SPECIFIED)
  );

  let pQOwner = getOrElse(row, MASTER_STATE_PATHS.PQ_OWNER);
  pQOwner = pQOwner || NOT_SPECIFIED;

  let sampleAvailable = getOrElse(row, MASTER_STATE_PATHS.SAMPLE_AVAILABLE);
  sampleAvailable = capitalize(sampleAvailable) || NOT_SPECIFIED;

  const sampleRequiredforInvestigationOptions =
    SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS.options;
  const sampleRequiredforInvestigationMatch = find(
    sampleRequiredforInvestigationOptions,
    ({ value }) =>
      value ===
      getOrElse(row, MASTER_STATE_PATHS.SAMPLE_REQUIRED_FOR_INVESTIGATION)
  );
  const sampleRequiredforInvestigation = get(
    sampleRequiredforInvestigationMatch,
    'label',
    NOT_SPECIFIED
  );
  

  let reactions = getOrElse(row, MASTER_STATE_PATHS.REACTIONS);
  reactions = reactions || NOT_SPECIFIED;

  let affiliateCountryLabel = getOrElse(
    row,
    MASTER_STATE_PATHS.AFFILIATE_COUNTRY_LABEL
  );
  affiliateCountryLabel = hasStringData(affiliateCountryLabel)
    ? affiliateCountryLabel
    : NOT_SPECIFIED;
  
  



  const users = get(tacticalData, 'document-data.user-list', []);
  const assigneeName = getUserName(users, row.assignee) || 'Unassigned';
  switch (label) {
    case 'Case Information':
      return row.id;
    case 'AE Suspect Product':
      return row.product;
    case 'Country of Reporter':
      return row.countryOfPrimaryReporterLabel;
    case 'AE Latest Received Date':
      return processNonMomentDate(
        abbvieDateFormat(getOrElse(row, AE_STATE_PATHS.LAST_RECEIVED_DATE))
      );
    case 'Owner':
      return assigneeName;
    case 'Status':
      return row.status;
    case 'Reactions':
      return reactions;
    case 'Due Date':
      return datedueDate;
    case 'Affiliate Awareness Date':
      return dateAffiliateAwarenessDate;
    case 'Abbvie Awareness (AE)':
      return abbvieAwarenessAE;
    case 'Abbvie Awareness (PQ)':
      return abbvieAwarenessPQ;
    case 'Trilogy Load Date':
      return datetrilogyLoadDate;
    case 'Seriousness':
      return seriousness;
    case 'Method of Receipt':
      return methodOfReceipt;
    case 'Case Creator':
      return row.creatorUsername;
    case 'High Impact':
      return highImpact || NOT_SPECIFIED;
    case 'PQ Owner':
      return pQOwner;
    case 'Replacement Required':
      return replacementRequired;
    case 'Sample Available':
      return sampleAvailable;
    case 'Sample Required for Investigation':
      return sampleRequiredforInvestigation;
    case 'Affiliate Location':
      return affiliateCountryLabel;
    

    default:
      return NOT_SPECIFIED;
  }
};

const getCasesToReviewColumnValue = (row, label, tacticalData) => {
  const trilogyLoadDate = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.TRILOGYLOADDATE)
  );

  const datetrilogyLoadDate = hasStringData(trilogyLoadDate)
    ? trilogyLoadDate
    : NOT_SPECIFIED;

  // const typeofContact = getOrElse(row, MASTER_STATE_PATHS.CONTACTTYPE);

  const serviceProviderOptions = get(
    tacticalData,
    'document-data.psp-options.psp_name',
    []
  );
  const serviceProviderMatch = find(
    serviceProviderOptions,
    ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.SERVICE_PROVIDER)
  );

  const serviceProvider = get(serviceProviderMatch, 'label', NOT_SPECIFIED);

  const programIDNameOptions = get(
    tacticalData,
    'document-data.psp-options.psp_opi_number',
    []
  );
  const programIDNameMatch = find(
    programIDNameOptions,
    ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.PROGRAM_ID_NAME)
  );

  const programIDName = get(programIDNameMatch, 'label', NOT_SPECIFIED);

  let thirdPartyPatientID = getOrElse(row, MASTER_STATE_PATHS.PATIENT_REPORTER);
  thirdPartyPatientID = thirdPartyPatientID || NOT_SPECIFIED;

  let programMarketedName = getOrElse(
    row,
    MASTER_STATE_PATHS.PROGRAM_MARKETED_NAME
  );
  programMarketedName = programMarketedName || NOT_SPECIFIED;

  let patientDOB = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.PATIENT_DOB)
  );
  patientDOB = patientDOB || NOT_SPECIFIED;

  let patientInitials = getOrElse(row, MASTER_STATE_PATHS.PATIENT_INITIALS);
  patientInitials = patientInitials || NOT_SPECIFIED;

  let batchLotNumber = getOrElse(row, MASTER_STATE_PATHS.BATCH_LOT_NUMBER);
  batchLotNumber = batchLotNumber || NOT_SPECIFIED;

  let owner = getOrElse(row, MASTER_STATE_PATHS.OWNER);
  owner = owner || NOT_SPECIFIED;

  let affiliateCountryLabel = getOrElse(
    row,
    MASTER_STATE_PATHS.AFFILIATE_COUNTRY_LABEL
  );
  affiliateCountryLabel = hasStringData(affiliateCountryLabel)
    ? affiliateCountryLabel
    : NOT_SPECIFIED;

  const formatDateTime = dt => moment(dt).format('DD MMM YYYY HH:mm:ss');
  const sentForTranslation = row.sentForTranslation || NOT_SPECIFIED;
  const documentPendingTranslation = row.documentPendingTranslation || NOT_SPECIFIED  
  let dateTimeFormat = formatDateTime(row.documentSentDate); 
  
  dateTimeFormat !== 'Invalid date' ? dateTimeFormat : NOT_SPECIFIED;

  const translation_statusOptions = get(
    tacticalData,
    'document-data.mastercase-options.translation_status',
    []
  );
  const translation_status_match = find(
    translation_statusOptions,
    ({ value }) =>
      value === getOrElse(row, MASTER_STATE_PATHS.TRANSLATION_STATUS)
  );
  const translation_status = get(
    translation_status_match,
    'label',
    MASTER_STATE_PATHS.TRANSLATION_NA
    // NOT_SPECIFIED
  );  

  switch (label) {
    case 'Case Information':
      return row.id;
    case 'Country of Reporter':
      return row.countryOfPrimaryReporterLabel || NOT_SPECIFIED;
    case 'Method of Receipt': {
      const methodOfReceiptOptions = get(
        tacticalData,
        'document-data.mastercase-options.method_of_receipt',
        []
      );
      const methodOfReceiptMatch = find(
        methodOfReceiptOptions,
        ({ value }) =>
          value === getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT)
      );
      return get(methodOfReceiptMatch, 'label', NOT_SPECIFIED);
    }
    case 'Abbvie Awareness (AE)': {
      const dateOutputAE = abbvieDateFormat(
        getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_AE)
      );
      return hasStringData(dateOutputAE) ? dateOutputAE : NOT_SPECIFIED;
    }
    case 'Abbvie Awareness (PQ)': {
      const dateOutputPQ = abbvieDateFormat(
        getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_PQ)
      );
      return hasStringData(dateOutputPQ) ? dateOutputPQ : NOT_SPECIFIED;
    }
    case 'Status':
      return STATUSES_MAP[get(row, 'status', 'NEW')];
    case 'Affiliate':
      return row.affiliate;
    case 'Current Revision':
      return row.currentRevision;
    case 'Archived':
      return row.archived;
    case 'Last Updated User Id':
      return row.lastUpdatedUserId;
    case 'Last Updated Username':
      return row.lastUpdatedUsername;
    case 'Subcase Creator UserName':
      return row.subcaseCreatorUserName;
    case 'Subcase Creator Id':
      return row.subcaseCreatorid;
    case 'Affiliate Awareness Date': {
      const affiliateAwarenessDate = abbvieDateFormat(
        getOrElse(row, 'summary.affiliate_awareness_date')
      );
      return hasStringData(affiliateAwarenessDate)
        ? affiliateAwarenessDate
        : NOT_SPECIFIED;
    }
    case 'Seriousness': {
      const seriousnessOptions = SERIOUSNESS_OPTIONS.options;
      const seriousnessOptionsMatch = find(
        seriousnessOptions,
        ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.SERIOUSNESS)
      );

      return get(seriousnessOptionsMatch, 'label', NOT_SPECIFIED);
    }
    case 'Master Case Product': {
      const masterCaseProductOptions = get(
        tacticalData,
        'document-data.mastercase-product-options'
      );

      const masterCaseProductMatch = find(
        masterCaseProductOptions,
        ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.PRODUCT_NAME)
      );

      const masterCaseProduct = get(
        masterCaseProductMatch,
        'label',
        NOT_SPECIFIED
      );
      return masterCaseProduct;
    }
    case 'Batch/Lot Number':
      return batchLotNumber;
    case 'Date of Birth':
      return patientDOB;
    case 'Initials':
      return patientInitials;
    case 'Program ID/Name':
      return programIDName;
    case 'Product Marketed Name':
      return programMarketedName;
    case 'Third Party Patient ID':
      return thirdPartyPatientID;
    case 'Service Provider':
      return serviceProvider;
    case 'Trilogy Load Date':
      return datetrilogyLoadDate;
    case 'Owner':
      return owner;
    case 'AGN/ABV': {
      return formatProductTypes(get(row, 'subcases.adverseEvent.productType'));
    }
    case 'Affiliate Location':
      return affiliateCountryLabel;
    case 'Documents Sent for Translation':
      return sentForTranslation;
    case 'Translation Status':  
      return translation_status;
    case 'Most Recent Date/Time of Documents Sent': 
      return dateTimeFormat;
    case 'Documents Pending Translation':  
      return documentPendingTranslation;  
    default:
      return '';
  }
};

const getMIColumnValue = (row, label, tacticalData) => {
  const users = get(tacticalData, 'document-data.user-list', []);
  const maybeAssignee = getUserName(
    users,
    getOrElse(row, MI_STATE_PATHS.ASSIGNEE)
  );
  const subcaseId = getOrElse(row, MI_STATE_PATHS.ID);
  const createdDate = getOrElse(row, MI_STATE_PATHS.CREATED);
  const productName = getSubcaseProductValue(
    row,
    tacticalData,
    CC_SUB_CASE_TYPES_MAP.mi,
    NOT_SPECIFIED
  );
  switch (label) {
    case 'Case Information':
      return subcaseId;
    case 'Country of Requester':
      return row.countryOfPrimaryReporterLabel;
    case 'Product':
      return productName;
    case 'Created On':
      return abbvieDateFormat(createdDate) || N_A;
    case 'MI Owner':
      return maybeAssignee || 'Unassigned';
    case 'Status':
      return STATUSES_MAP[getOrElse(row, MI_STATE_PATHS.STATUS, 'NEW')];
    default:
      return NOT_SPECIFIED;
  }
};

const getMasterCaseColumnValue = (row, label, tacticalData) => {
  const methodOfReceiptOptions = get(
    tacticalData,
    'document-data.mastercase-options.method_of_receipt',
    []
  );
  const methodOfReceiptMatch = find(
    methodOfReceiptOptions,
    ({ value }) =>
      value === getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT)
  );

  const methodOfReceipt = get(methodOfReceiptMatch, 'label', NOT_SPECIFIED);

  const masterCaseProductOptions = get(
    tacticalData,
    'document-data.mastercase-product-options'
  );

  const masterCaseProductMatch = find(
    masterCaseProductOptions,
    ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.PRODUCT_NAME)
  );

  const masterCaseProduct = get(masterCaseProductMatch, 'label', NOT_SPECIFIED);

  const seriousnessOptions = SERIOUSNESS_OPTIONS.options;

  const seriousnessOptionsMatch = find(
    seriousnessOptions,
    ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.SERIOUSNESS)
  );

  const seriousness = get(seriousnessOptionsMatch, 'label', NOT_SPECIFIED);

  const dateOutputAE = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_AE)
  );

  const dateOutputPQ = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_PQ)
  );

  const abbvieAwarenessAE = hasStringData(dateOutputAE)
    ? dateOutputAE
    : NOT_SPECIFIED;

  const abbvieAwarenessPQ = hasStringData(dateOutputPQ)
    ? dateOutputPQ
    : NOT_SPECIFIED;

  const affiliateAwarenessDate = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.SUMMARY_AFFILIATE_AWARENESS_DATE)
  );

  const dateAffiliateAwarenessDate = hasStringData(affiliateAwarenessDate)
    ? affiliateAwarenessDate
    : NOT_SPECIFIED;

  const trilogyLoadDate = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.TRILOGYLOADDATE)
  );

  const datetrilogyLoadDate = hasStringData(trilogyLoadDate)
    ? trilogyLoadDate
    : NOT_SPECIFIED;

  const typeofContactOptions = get(
    tacticalData,
    'document-data.mastercase-options.type_of_contact',
    []
  );
  const typeofContactMatch = find(
    typeofContactOptions,
    ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.CONTACT_TYPE)
  );

  const typeofContact = get(typeofContactMatch, 'label', NOT_SPECIFIED);

  const serviceProviderOptions = get(
    tacticalData,
    'document-data.psp-options.psp_name',
    []
  );
  const serviceProviderMatch = find(
    serviceProviderOptions,
    ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.SERVICE_PROVIDER)
  );

  const serviceProvider = get(serviceProviderMatch, 'label', NOT_SPECIFIED);

  const programIDNameOptions = get(
    tacticalData,
    'document-data.psp-options.psp_opi_number',
    []
  );
  const programIDNameMatch = find(
    programIDNameOptions,
    ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.PROGRAM_ID_NAME)
  );

  const programIDName = get(programIDNameMatch, 'label', NOT_SPECIFIED);

  let thirdPartyPatientID = getOrElse(row, MASTER_STATE_PATHS.PATIENT_REPORTER);
  thirdPartyPatientID = thirdPartyPatientID || NOT_SPECIFIED;

  let programMarketedName = getOrElse(
    row,
    MASTER_STATE_PATHS.PROGRAM_MARKETED_NAME
  );
  programMarketedName = programMarketedName || NOT_SPECIFIED;

  let patientDOB = abbvieDateFormat(
    getOrElse(row, MASTER_STATE_PATHS.PATIENT_DOB)
  );
  patientDOB = patientDOB || NOT_SPECIFIED;

  let patientInitials = getOrElse(row, MASTER_STATE_PATHS.PATIENT_INITIALS);
  patientInitials = patientInitials || NOT_SPECIFIED;

  let contactReferenceNumber = getOrElse(
    row,
    MASTER_STATE_PATHS.CONTACT_REFERENCE_NUMBER
  );
  contactReferenceNumber = contactReferenceNumber || NOT_SPECIFIED;

  const dueDate = abbvieDateFormat(getOrElse(row, MASTER_STATE_PATHS.DUEDATE));

  const datedueDate = hasStringData(dueDate) ? dueDate : NOT_SPECIFIED;

  const highImpact = capitalize(get(row, MASTER_STATE_PATHS.HIGH_IMPACT, ''));

  let pQOwner = getOrElse(row, MASTER_STATE_PATHS.PQ_OWNER);
  pQOwner = pQOwner || NOT_SPECIFIED;

  let replacementRequired = getOrElse(
    row,
    MASTER_STATE_PATHS.REPLACEMENT_REQUIRED
  );
  replacementRequired = replacementRequired || NOT_SPECIFIED;

  let sampleAvailable = getOrElse(row, MASTER_STATE_PATHS.SAMPLE_AVAILABLE);
  sampleAvailable = sampleAvailable || NOT_SPECIFIED;

  let sampleRequiredforInvestigation = getOrElse(
    row,
    MASTER_STATE_PATHS.SAMPLE_REQUIRED_FOR_INVESTIGATION
  );
  sampleRequiredforInvestigation =
    sampleRequiredforInvestigation || NOT_SPECIFIED;

  let death = getOrElse(row, MASTER_STATE_PATHS.DEATH);
  death = death || NOT_SPECIFIED;

  const aeSeriousOptionsMatch = find(
    seriousnessOptions,
    ({ value }) =>
      value ===
      getOrElse(row, MASTER_STATE_PATHS.ADVERSEEVENT_SAFETY_SERIOUSNESS)
  );

  const aeSerious = get(aeSeriousOptionsMatch, 'label', NOT_SPECIFIED);

  let batchLotNumber = getOrElse(row, MASTER_STATE_PATHS.BATCH_LOT_NUMBER);
  batchLotNumber = batchLotNumber || NOT_SPECIFIED;

  let owner = getOrElse(row, MASTER_STATE_PATHS.OWNER);
  owner = owner || NOT_SPECIFIED;

  let reactions = getOrElse(row, MASTER_STATE_PATHS.REACTIONS);
  reactions = reactions || NOT_SPECIFIED;

  let affiliateCountryLabel = getOrElse(
    row,
    MASTER_STATE_PATHS.AFFILIATE_COUNTRY_LABEL
  );
  affiliateCountryLabel = hasStringData(affiliateCountryLabel)
    ? affiliateCountryLabel
    : NOT_SPECIFIED;

  switch (label) {
    case 'Case Information':
      return row.id;
    case 'Country of Reporter':
      return row.countryOfPrimaryReporterLabel;
    case 'Method of Receipt':
      return methodOfReceipt;
    case 'Abbvie Awareness (AE)':
      return abbvieAwarenessAE;
    case 'Abbvie Awareness (PQ)':
      return abbvieAwarenessPQ;
    case 'Affiliate':
      return row.affiliate;
    case 'Current Revision':
      return row.currentRevision;
    case 'Archived':
      return row.archived;
    case 'Last Updated User Id':
      return row.lastUpdatedUserId;
    case 'Last Updated Username':
      return row.lastUpdatedUsername;
    case 'Subcase Creator UserName':
      return row.subcaseCreatorUserName;
    case 'Case Creator':
      return row.creatorUsername || NOT_SPECIFIED;
    case 'Subcase Creator Id':
      return row.subcaseCreatorid;
    case 'Affiliate Awareness Date':
      return dateAffiliateAwarenessDate;
    case 'Seriousness':
      return seriousness;
    case 'Master Case Product':
    case 'Product':
      return masterCaseProduct;
    case 'Trilogy Load Date':
      return datetrilogyLoadDate;
    case 'Type of Contact':
      return typeofContact;
    case 'Third Party Patient ID':
      return thirdPartyPatientID;
    case 'Service Provider':
      return serviceProvider;
    case 'Program ID/Name':
      return programIDName;
    case 'Product Marketed Name':
      return programMarketedName;
    case 'Date of Birth':
      return patientDOB;
    case 'Initials':
      return patientInitials;
    case 'Contact Reference Number':
      return contactReferenceNumber;
    case 'Due Date':
      return datedueDate;
    case 'High Impact':
      return highImpact;
    case 'PQ Owner':
      return pQOwner;
    case 'Replacement Required':
      return replacementRequired;
    case 'Sample Available':
      return sampleAvailable;
    case 'Sample Required for Investigation':
      return sampleRequiredforInvestigation;
    case 'Death':
      return death.toString();
    case 'AE Serious':
      return aeSerious;
    case 'Batch/Lot Number':
      return batchLotNumber;
    case 'Reactions':
      return reactions;
    case 'Status':
      return STATUSES_MAP[get(row, 'status', 'NEW')];
    case 'Owner':
      return owner;
    case 'Affiliate Location':
      return affiliateCountryLabel;
    default:
      return NOT_SPECIFIED;
  }
};

const getTasksColumnValue = (value, label, tacticalData, column, subcases) => {
  let followUpType = getOrElse(value, column.sortPath);
  followUpType = followUpType || NOT_SPECIFIED;

  let numberofAttempts = getOrElse(value, column.sortPath);
  numberofAttempts = numberofAttempts || NOT_SPECIFIED;

  const getDescription = () => TYPE_MAP[value.form.additional.type];

  const { caseId, subcaseId } = value.form.base;
  const renderId = () => {
    const isNewTask = value.id === NEW_TASK_ID;
    return isNewTask ? 'Not saved' : value.id;
  };

  const seriousness = SERIOUSNESS_OPTIONS_TASKS[get(value, SERIOUSNESS_TASKS)];
  const relations = get(value, REACTIONS_TASKS);
  const globalId = getOrElse(value, column.sortPath, NOT_SPECIFIED);
  const communicationMethod = getOrElse(value, column.sortPath, NOT_SPECIFIED);

  let initialAERIndex;
  let initialArgusIndex;

  if (Object.keys(subcases).length !== 0) {
    const { subcaseVersions } = subcases.adverseEvent;
    initialAERIndex = subcaseVersions.findIndex(val => val.aerNumber != null);
    initialArgusIndex = subcaseVersions.findIndex(
      val => val.argusNumber != null
    );
  }

  const argusNumber = getOrElse(
    value,
    column.sortPath,
    initialArgusIndex
      ? get(
          subcases,
          `adverseEvent.subcaseVersions[${initialArgusIndex}].argusNumber`
        )
      : NOT_SPECIFIED
  );
  const aerNumber = getOrElse(
    value,
    column.sortPath,
    initialAERIndex
      ? get(
          subcases,
          `adverseEvent.subcaseVersions[${initialAERIndex}].aerNumber`
        )
      : NOT_SPECIFIED
  );

  switch (label) {
    case 'Description':
      return `${
        value.form.base.description
      }\u00A0${getDescription()}\u00A0${renderId()}`;

    case 'Case':
      return !isNil(subcaseId) && !isEmpty(subcaseId) ? subcaseId : caseId;

    case 'Due Date':
      return value.form.base.dueDate
        ? value.form.base.dueDate
            .split(';')
            .map(date => abbvieDateFormat(date))
            .join('; ')
        : NOT_SPECIFIED;

    case 'Assignee':
      return (
        getUserName(
          get(tacticalData, 'document-data.user-list', []),
          value.form.base.assignee
        ) || 'Unassigned'
      );
    case 'Follow Up Type':
      return followUpType;
    case 'Number of attempt':
      return numberofAttempts;
    case 'Seriousness':
      return seriousness;
    case 'Reactions':
      return relations;
    case 'Global ID':
      return globalId;
    case 'Communication Method':
      return communicationMethod;
    case 'Argus Number':
      return argusNumber;
    case 'AER Number':
      return aerNumber;
    default:
      return NOT_SPECIFIED;
  }
};
/* eslint import/prefer-default-export:0 */
export const getColumnValueForCSVExport = (
  row = {},
  label = '',
  currentPage = '',
  tacticalData = {},
  column = {},
  subcases = {}
) => {
  if (currentPage === 'ae') {
    return getAEColumnValue(row, label, tacticalData);
  }
  if (currentPage === 'pq') {
    return getPQColumnValue(row, label, tacticalData);
  }
  if (currentPage === 'yours') {
    return getYourCaseColumnValue(row, label, tacticalData);
  }
  if (currentPage === 'new') {
    return getCasesToReviewColumnValue(row, label, tacticalData);
  }
  if (currentPage === 'mi') {
    return getMIColumnValue(row, label, tacticalData);
  }
  if (currentPage === 'allTasks' || currentPage === 'yourTasks') {
    return getTasksColumnValue(row, label, tacticalData, column, subcases);
  }
  return getMasterCaseColumnValue(row, label, tacticalData);
};
