import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNil, get, set, clone, unset } from 'lodash';
import { Link } from 'react-router-dom';
import Indicator from 'Common/components/Indicator';

import {
  API_DATE_FORMAT,
  DATE_FORMAT,
  abbvieDateFormat,
  withStyles,
  LabelValue
} from 'Common/components/Form';
import CaseNotes from 'Common/components/CaseNotes';
import {
  CASE_STATUS_NEW,
  CASE_STATUS_IN_PROGRESS,
  CASE_STATUS_ARCHIVED,
  CASE_STATUS_COMPLETED,
  CC_SUB_CASE_TYPES_MAP,
  NOT_SPECIFIED,
  CMS_PROP_TYPES
} from 'Common/constants';
import { FORM_BASE_PATH } from 'CreateCase/constants';
import { PQ_STATE_PATHS } from 'Queue/constants';
import SeriousnessIndicator from 'Common/components/SeriousnessIndicator';
import {
  tasksForCaseLink,
  isSubcaseRoute,
  processSeriousnessCriteria,
  getUserName,
  getOrElse,
  getMethodOfReceiptLabel
} from 'Common/utils';
import BreadCrumbs from './BreadCrumbs';
import CaseActionsMenu from './CaseActionsMenu';
import AffiliateSelect from './AffiliateSelect';
import stylesGenerator from './styles';

// Note for dynamic values in the header that change based on form input,
// be sure that field is specified in: src/api/graphql/mutations/createCaseMutation.graphql
class PageHeader extends PureComponent {
  static propTypes = {
    pageSchema: PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        page: PropTypes.string,
        masterCaseId: PropTypes.string
      }).isRequired
    }).isRequired,
    tasksCount: PropTypes.number,
    actions: PropTypes.shape({
      emitFetchTasksCount: PropTypes.func.isRequired
    }).isRequired,
    // viewTasksLink: PropTypes.func.isRequired,
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired,
    actionsMenuLinks: PropTypes.node,
    computedStyles: PropTypes.shape({
      divider: PropTypes.object.isRequired,
      row: PropTypes.object.isRequired,
      twoRow: PropTypes.object.isRequired,
      title: PropTypes.object.isRequired,
      refNum: PropTypes.object.isRequired
    }).isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData,
    isCaseLocked: PropTypes.bool.isRequired,
    isCaseLockedManualDismiss: PropTypes.bool.isRequired
  };

  static defaultProps = {
    tasksCount: 0,
    actionsMenuLinks: null,
    tacticalData: {},
    isCaseLocked: false,
    isCaseLockedManualDismiss: false
  };

  componentWillMount() {
    this.fetchTasksCount(this.props);
  }

  componentWillUpdate(nextProps) {
    const { match: { params } } = nextProps;
    const { match: { params: oldParams }, pageSchema } = this.props;
    const { masterCaseId } = this.props.match.params;

    document.title = masterCaseId
      ? `${masterCaseId} ${pageSchema.title}`
      : pageSchema.title;

    if (
      params.masterCaseId !== oldParams.masterCaseId ||
      params.page !== oldParams.page
    ) {
      this.fetchTasksCount(nextProps);
    }
  }

  shouldBeDisplayed = () => !!this.props.pageSchema.title;

  fetchTasksCount = props => {
    const { match } = props;
    const masterCaseId = getOrElse(match, 'params.masterCaseId');

    const subcaseType = isSubcaseRoute(match)
      ? {
          subcaseType: match.params.page
        }
      : {};
    if (!isNil(masterCaseId)) {
      this.props.actions.emitFetchTasksCount({
        form: {
          base: {
            caseId: masterCaseId,
            ...subcaseType
          }
        },
        status: 'OPEN'
      });
    }
  };

  renderReferenceNum = () => {
    const { computedStyles, match, trilogyCase } = this.props;
    const seriousnessValue = processSeriousnessCriteria(trilogyCase);
    const isSubcase = isSubcaseRoute(match);
    const caseId = getOrElse(
      trilogyCase,
      isSubcase
        ? `subcases.${CC_SUB_CASE_TYPES_MAP[match.params.page]}.id`
        : 'id',
      match.params.masterCaseId
    );

    return (
      <div className={computedStyles.refNum}>
        <span data-id="case-id">{caseId}</span>
        {seriousnessValue ? (
          <SeriousnessIndicator value={seriousnessValue} pullRight={false} />
        ) : null}
      </div>
    );
  };

  renderPageTitle = () => {
    const { computedStyles, pageSchema, match, trilogyCase } = this.props;

    const currentPage = match.params.page;
    const isAe = currentPage === 'ae';
    const isPq = currentPage === 'pq';
    const isMcDashboard = currentPage === 'review';

    const onPageThatSupportsReconcilliation = ['ae', 'pq', 'review'].includes(
      currentPage
    );
    const aeSubcaseNeedsReconcilliation = get(
      trilogyCase,
      'subcases.adverseEvent.reconciliationNeeded',
      false
    );
    const pqSubcaseNeedsReconcilliation = get(
      trilogyCase,
      'subcases.productQuality.reconciliationNeeded',
      false
    );
    // on ae and ae subcase needs reconcilliation
    // or on pq and pq subcase needs reconcilliation,
    // or on mc dashboard and either ae or pq subcase needs reconcilliation
    const showReconcilliation =
      (onPageThatSupportsReconcilliation &&
        isAe &&
        aeSubcaseNeedsReconcilliation) ||
      (onPageThatSupportsReconcilliation &&
        isPq &&
        pqSubcaseNeedsReconcilliation) ||
      (onPageThatSupportsReconcilliation &&
        isMcDashboard &&
        (aeSubcaseNeedsReconcilliation || pqSubcaseNeedsReconcilliation));

    const title = showReconcilliation ? (
      <Fragment>
        {pageSchema.title} <Indicator>R</Indicator>
      </Fragment>
    ) : (
      pageSchema.title
    );
    return (
      <div className={computedStyles.title}>
        <LabelValue $id="case.header.pageTitle" value={title} />
      </div>
    );
  };

  renderTasksCount = () => {
    const { tasksCount } = this.props;
    return tasksCount !== null && tasksCount > 0 ? `(${tasksCount})` : null;
  };

  renderTasksViewLink = () => {
    const { computedStyles, match } = this.props;
    const { masterCaseId } = match.params;
    const caseLink = tasksForCaseLink(match, masterCaseId);
    return (
      <div className={computedStyles.tasks} data-id="header-link--view-tasks">
        <Link to={caseLink} target="_blank">
          View Tasks {this.renderTasksCount()}
        </Link>
      </div>
    );
  };

  renderMasterCaseLink = () => {
    const { computedStyles, match } = this.props;
    const CASE_LINK = `/${FORM_BASE_PATH}/${match.params.masterCaseId}/review`;
    return (
      <div
        className={computedStyles.tasks}
        data-id="case.header.viewMasterCase"
      >
        <Link to={CASE_LINK}>View Master Case</Link>
      </div>
    );
  };

  renderSwitchCaseLink = () => {
    const { match, computedStyles } = this.props;
    const switchPath = match.params.page === 'ae' ? 'pq' : 'ae';
    const subCaseLink = `/${FORM_BASE_PATH}/${
      match.params.masterCaseId
    }/${switchPath}`;
    return (
      <div className={computedStyles.tasks} data-id="header-link--switch-tasks">
        <Link to={subCaseLink}>View {switchPath.toUpperCase()}</Link>
      </div>
    );
  };

  renderCaseInfo = () => {
    switch (this.props.pageSchema.path) {
      case 'master':
        return this.renderHeaderForCreateCase();
      case 'ae':
        return this.renderHeaderForAE();
      case 'pq':
        return this.renderHeaderForPQ();
      case 'review':
        return this.renderHeaderForDashboard();
      case 'mi':
        return this.renderHeaderForMI();
      default:
        return null;
    }
  };

  renderTrilogyLoadDate = () => {
    const { trilogyLoadDate, isLoadingCase } = this.props.trilogyCase;
    if (!isNil(trilogyLoadDate)) {
      return abbvieDateFormat(moment(trilogyLoadDate));
    } else if (isLoadingCase) {
      return 'Loading...';
    }
    return 'Invalid Trilogy Load Date';
  };

  renderAutomatedStatusLabel = () => {
    const { automationStatus } = this.props.trilogyCase;
    return (
      <LabelValue
        $id="caseCreation.header.automationStatus"
        label="Automated Process"
        value={automationStatus}
      />
    );
  };

  renderHeaderForCreateCase = () => {
    const {
      computedStyles,
      tacticalData,
      trilogyCase,
      actions,
      match
    } = this.props;
    const formattedLoadDate = this.renderTrilogyLoadDate();
    const affiliateOptions = getOrElse(
      tacticalData,
      'dynamic-tactical-data.allowed-affiliates',
      []
    )
      .map((aff, i) => {
        if (!aff.deprecated && !aff.value.match(/[a-z]+-\d/)) {
          const updatedAff = clone(aff);
          updatedAff.value = `${aff.value}-${i}`;
          return updatedAff;
        }
        return null;
      })
      .filter(aff => aff);

    return (
      <div className={computedStyles.row}>
        <LabelValue
          $id="caseCreation.header.trilogyLoadDate"
          label="Trilogy Load Date"
          value={formattedLoadDate}
        />
        {this.renderAutomatedStatusLabel()}
        {trilogyCase.affiliateCountryLabel ? (
          <LabelValue
            $id="caseCreation.header.affiliateLocation"
            label="Affiliate Location"
            value={trilogyCase.affiliateCountryLabel}
          />
        ) : (
          <AffiliateSelect
            className={computedStyles.affiliateSelect}
            $id="caseCreation.header.affiliateLocation"
            label="Affiliate Location"
            value={trilogyCase.affiliateSelectedValue}
            onChange={value => {
              set(trilogyCase, 'affiliate', value.replace(/-\d+/, ''));
              set(trilogyCase, 'affiliateSelectedValue', value);
              actions.emitInputBatchUpdate(trilogyCase);
            }}
            options={affiliateOptions}
            onClick={() => {
              set(
                trilogyCase,
                'affiliateCountryLabel',
                affiliateOptions.find(
                  affiliate =>
                    trilogyCase.affiliateSelectedValue === affiliate.value
                ).label
              );
              unset(trilogyCase, 'affiliateSelectedValue');
              actions.emitSaveCase(trilogyCase, match.params);
            }}
          />
        )}
      </div>
    );
  };

  renderCountryOfReporter = () => {
    const { trilogyCase, tacticalData } = this.props;
    const countryMatch = getOrElse(
      tacticalData,
      'document-data.country-options',
      []
    ).find(c => c.value === trilogyCase.countryOfPrimaryReporter);
    return getOrElse(countryMatch, 'label', NOT_SPECIFIED);
  };

  renderHeaderForAE = () => {
    const { computedStyles, trilogyCase, tacticalData } = this.props;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const latestReceivedDate = getOrElse(
      trilogyCase,
      'subcases.adverseEvent.aerinfo.safety.last_received_date'
    );
    const rawSubmissionDate = getOrElse(
      trilogyCase,
      'subcases.adverseEvent.aerinfo.safety.submission_due_date',
      null
    );

    const formattedSubmissionDate = rawSubmissionDate
      ? moment(
          rawSubmissionDate,
          [moment.ISO_8601, API_DATE_FORMAT],
          true
        ).format(DATE_FORMAT)
      : NOT_SPECIFIED;

    let valueStyles = null;
    if (formattedSubmissionDate !== NOT_SPECIFIED) {
      const submissionDate = moment(
        rawSubmissionDate,
        [moment.ISO_8601, API_DATE_FORMAT],
        true
      );
      const today = moment();
      const tomorrow = moment().add(1, 'days');

      if (submissionDate.isBefore(today, 'days')) {
        valueStyles = computedStyles.dueDateRed;
      } else if (
        submissionDate.isSame(today, 'days') ||
        submissionDate.isSame(tomorrow, 'days')
      ) {
        valueStyles = computedStyles.dueDateOrange;
      }
    }
    const seriousnessMap = {
      serious: 'Serious',
      other: 'Serious',
      death: 'Serious',
      life_threat: 'Serious',
      hospital: 'Serious',
      disabling: 'Serious',
      congenital: 'Serious',
      nonserious: 'Non Serious'
    };
    const caseSeriousnessPath =
      'subcases.adverseEvent.aerinfo.safety.seriousness';

    const seriousness = getOrElse(trilogyCase, caseSeriousnessPath);
    const countryOfMCPrimaryReporter = this.renderCountryOfReporter();
    const auth0 = getOrElse(trilogyCase, 'subcases.adverseEvent.assignee');
    const aeOwner = auth0 ? getUserName(users, auth0) || auth0 : NOT_SPECIFIED;

    return (
      <div className={`${computedStyles.row} ${computedStyles.twoRow}`}>
        <LabelValue
          $id="subcases.adverseEvent.LRD"
          label="Latest Received Date"
          value={abbvieDateFormat(latestReceivedDate) || NOT_SPECIFIED}
        />
        <LabelValue
          $id="subcases.adverseEvent.SDD"
          label="Submission Due Date"
          value={formattedSubmissionDate}
          valueStyles={valueStyles}
        />
        <LabelValue
          $id="subcases.adverseEvent.submitted"
          label="Submitted?"
          value={
            getOrElse(trilogyCase, 'subcases.adverseEvent.submitted', false)
              ? 'Yes'
              : 'No'
          }
        />
        <LabelValue
          $id="subcases.adverseEvent.seriousness"
          id="page-header-seriousness"
          label="Seriousness"
          value={seriousnessMap[seriousness] || NOT_SPECIFIED}
        />
        <LabelValue
          $id="subcases.adverseEvent.owner"
          label="AE Owner"
          value={aeOwner}
        />
        <LabelValue
          $id="subcases.adverseEvent.CPR"
          label="Country of Primary Reporter"
          value={countryOfMCPrimaryReporter}
        />
        <LabelValue
          $id="subcases.adverseEvent.owner"
          label="Affiliate Location"
          value={trilogyCase.affiliateCountryLabel}
        />
        {this.renderAutomatedStatusLabel()}
      </div>
    );
  };

  renderHeaderForMI = () => {
    const { computedStyles, trilogyCase, tacticalData } = this.props;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const createdDate = getOrElse(
      trilogyCase,
      'subcases.medicalInfo.createdDate'
    );
    const methodOfReceipt = getOrElse(
      trilogyCase,
      'tracking.methodOfReceipt',
      NOT_SPECIFIED
    );
    const countryOfMCPrimaryReporter = this.renderCountryOfReporter();
    const auth0 = getOrElse(trilogyCase, 'subcases.medicalInfo.assignee');
    const miOwner = auth0 ? getUserName(users, auth0) || auth0 : NOT_SPECIFIED;

    return (
      <div className={`${computedStyles.row} ${computedStyles.twoRow}`}>
        <LabelValue
          label="Date Created"
          value={abbvieDateFormat(createdDate) || NOT_SPECIFIED}
        />
        <LabelValue label="Method of Receipt" value={methodOfReceipt} />
        <LabelValue label="MI Owner" value={miOwner} />
        <LabelValue
          label="Country of Primary Reporter"
          value={countryOfMCPrimaryReporter}
        />
        <LabelValue
          label="Affiliate Location"
          value={trilogyCase.affiliateCountryLabel}
        />
      </div>
    );
  };

  renderHeaderForPQ = () => {
    const { computedStyles, trilogyCase, tacticalData } = this.props;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const createdDate = getOrElse(
      trilogyCase,
      'subcases.productQuality.createdDate'
    );
    // TODO: Map value to label
    const product = getOrElse(
      trilogyCase,
      PQ_STATE_PATHS.PRODUCT_NAME,
      NOT_SPECIFIED
    );
    const countryOfMCPrimaryReporter = this.renderCountryOfReporter();
    const auth0 = getOrElse(trilogyCase, 'subcases.productQuality.assignee');
    const pqOwner = auth0 ? getUserName(users, auth0) || auth0 : NOT_SPECIFIED;

    return (
      <div className={`${computedStyles.row} ${computedStyles.twoRow}`}>
        <LabelValue
          label="Date Created"
          value={abbvieDateFormat(createdDate) || NOT_SPECIFIED}
        />
        <LabelValue label="Product (PQ)" value={product} />
        <LabelValue label="PQ Owner" value={pqOwner} />
        <LabelValue
          label="Country of Primary Reporter"
          value={countryOfMCPrimaryReporter}
        />
        <LabelValue
          label="Affiliate Location"
          value={trilogyCase.affiliateCountryLabel}
        />
      </div>
    );
  };

  renderHeaderForDashboard = () => {
    const { computedStyles, trilogyCase, tacticalData } = this.props;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const formattedLoadDate = this.renderTrilogyLoadDate();
    const auth0 = getOrElse(trilogyCase, 'creatorid');
    const creator = auth0 ? getUserName(users, auth0) || auth0 : NOT_SPECIFIED;
    const awarenessDate = getOrElse(trilogyCase, 'summary.awareness_date');
    const pqAwarenessDate = getOrElse(
      trilogyCase,
      'summary.productQuality.awareness_date'
    );
    const methodOfReceiptKey = getOrElse(
      trilogyCase,
      'tracking.methodOfReceipt',
      NOT_SPECIFIED
    );

    const methodOfReceipt =
      methodOfReceiptKey === NOT_SPECIFIED
        ? methodOfReceiptKey
        : getMethodOfReceiptLabel(tacticalData, methodOfReceiptKey);

    const countryOfMCPrimaryReporter = this.renderCountryOfReporter();

    return (
      <div className={`${computedStyles.row} ${computedStyles.twoRow}`}>
        <LabelValue
          $id="masterCase.header.trilogyLoadDate"
          label="Trilogy Load Date"
          value={formattedLoadDate}
        />
        <LabelValue
          $id="masterCase.header.abbvieAwarenessDate"
          label="AbbVie Awareness (Adverse Event)"
          value={abbvieDateFormat(awarenessDate) || NOT_SPECIFIED}
        />
        <LabelValue
          $id="masterCase.header.abbviePQPAwarenessDate"
          label="Abbvie Awareness (PQ Complaint)"
          value={abbvieDateFormat(pqAwarenessDate) || NOT_SPECIFIED}
        />
        <LabelValue
          $id="masterCase.header.methodOfReceipt"
          label="Method of Receipt"
          value={methodOfReceipt}
        />
        <LabelValue
          $id="masterCase.header.countryOfPrimaryReporterRequester"
          label="Country of Primary Reporter/Requester"
          value={countryOfMCPrimaryReporter}
        />
        <LabelValue
          $id="masterCase.header.caseCreator"
          label="Case Creator"
          value={creator}
        />
        <LabelValue
          $id="masterCase.header.affiliateLocation"
          label="Affiliate Location"
          value={trilogyCase.affiliateCountryLabel}
        />
        {this.renderAutomatedStatusLabel()}
      </div>
    );
  };

  renderCaseActionMenuLinks = (isMenuDisabled = false) => (
    <CaseActionsMenu isMenuDisabled={isMenuDisabled}>
      {this.props.actionsMenuLinks}
    </CaseActionsMenu>
  );

  renderCaseNotes = () => {
    const { trilogyCase, actions } = this.props;
    return (
      <CaseNotes
        trilogyCase={trilogyCase}
        updateCase={actions.emitInputBatchUpdate}
      />
    );
  };

  renderPageHeader = () => {
    const {
      computedStyles,
      actionsMenuLinks,
      match,
      trilogyCase,
      isCaseLocked,
      isCaseLockedManualDismiss
    } = this.props;
    const { summary } = trilogyCase;
    const isSubcase = isSubcaseRoute(match);
    const status = getOrElse(
      trilogyCase,
      isSubcase
        ? `subcases.${CC_SUB_CASE_TYPES_MAP[match.params.page]}.status`
        : 'status',
      'NEW'
    );

    const completedStatusMap = {
      [CASE_STATUS_NEW]: 0,
      [CASE_STATUS_IN_PROGRESS]: 1,
      [CASE_STATUS_ARCHIVED]: 2,
      [CASE_STATUS_COMPLETED]: 2
    };

    return (
      <div className={computedStyles.base}>
        <div className={computedStyles.row}>
          {this.renderPageTitle()}
          {this.renderReferenceNum()}
        </div>
        <div className={computedStyles.row}>
          <BreadCrumbs
            status={status}
            total={3}
            completed={completedStatusMap[status]}
          />
          <div className={computedStyles.end}>
            {this.renderTasksViewLink()}
            {isSubcaseRoute(match) ? (
              <React.Fragment>
                {this.renderMasterCaseLink()}
                {summary.narrative.categories.product_quality &&
                summary.narrative.categories.adverse_event
                  ? this.renderSwitchCaseLink()
                  : ''}
              </React.Fragment>
            ) : null}
            <div className={computedStyles.actionMenu}>
              {actionsMenuLinks
                ? this.renderCaseActionMenuLinks(
                    isCaseLocked || isCaseLockedManualDismiss
                  )
                : null}
            </div>
            {this.renderCaseNotes()}
          </div>
        </div>
        {this.renderCaseInfo()}
        <hr className={computedStyles.divider} />
      </div>
    );
  };

  render() {
    return this.shouldBeDisplayed ? this.renderPageHeader() : null;
  }
}

export default withStyles(stylesGenerator)(PageHeader);
